<template>
  <div :class="device.desktop ? `home pa-6 grey lighten-4`:`home pa-0 grey lighten-4`">
    <v-row>
      <v-col col="12">
        <v-card class="animated fadeInUp">
          <v-card-title class="flex flex-row-reverse purple lighten-1">
            <v-tooltip
              color="white"
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  small
                  icon
                  v-on="on"
                >
                  <v-icon
                    color="white"
                    @click="
                      $router.push({ name: 'operator-surat-keluar-index' })
                    "
                  >cancel</v-icon>
                </v-btn>
              </template>
              <span>Tutup</span>
            </v-tooltip>
            <v-tooltip
              color="green"
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  small
                  icon
                  v-on="on"
                >
                  <v-icon
                    color="white"
                    @click="fetchRecords"
                  >refresh</v-icon>
                </v-btn>
              </template>
              <span>Refresh Data</span>
            </v-tooltip>
            <v-tooltip
              :color="theme.color"
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  small
                  icon
                  v-on="on"
                >
                  <v-icon
                    color="white"
                    @click="postDeleteAllRecord"
                  >mdi-delete-sweep</v-icon>
                </v-btn>
              </template>
              <span>Hapus Semua Kiriman</span>
            </v-tooltip>
            <v-tooltip
              :color="theme.color"
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  small
                  icon
                  v-on="on"
                >
                  <v-icon
                    color="white"
                    @click="openAddNew"
                  >mdi-send-check</v-icon>
                </v-btn>
              </template>
              <span>Kirim Berdasarkan Pilihan</span>
            </v-tooltip>
            <v-tooltip
              :color="theme.color"
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  small
                  icon
                  v-on="on"
                >
                  <v-icon
                    color="white"
                    @click="postAddNewRecordAllOpd"
                  >mdi-send</v-icon>
                </v-btn>
              </template>
              <span>Kirim Ke Seluruh OPD</span>
            </v-tooltip>

            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pencarian"
              single-line
              hide-details
              solo
              :color="theme.color"
              dense
            ></v-text-field>
          </v-card-title>
          <v-data-table
            v-show="device.desktop"
            :headers="headers"
            :items="records"
            :items-per-page="10"
            class="elevation-2"
            :color="theme.color"
            :loading="loading.table"
            loading-text="Loading... Please wait"
            :search="search"
            show-select
          >
            <v-progress-linear
              slot="progress"
              :color="theme.color"
              height="1"
              indeterminate
            ></v-progress-linear>

            <template v-slot:item.sifat_surat="{ value }">
              <v-icon :color="value.color">{{ value.icon }}</v-icon>
            </template>

            <template v-slot:item.status="{ value }">
              <v-chip
                small
                :color="value.color"
              >{{ value.text }}</v-chip>
            </template>

            <template v-slot:item.id="{ value }">
              <v-tooltip
                color="red"
                bottom
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    text
                    small
                    icon
                    v-on="on"
                  >
                    <v-icon
                      color="red"
                      @click="postDeleteRecord(value)"
                    >mdi-delete</v-icon>
                  </v-btn>
                </template>

                <span>Hapus</span>
              </v-tooltip>
            </template>
          </v-data-table>
          <v-list
            subheader
            three-line
            v-show="device.mobile"
          >

            <v-list-item-group
              multiple
              active-class=""
            >
              <v-list-item
                v-for="item in filterItems"
                :key="item.id"
                style="border: ;1px solid"
              >
                <template v-slot:default="{ active }">
                  <v-list-item-action>
                    <v-checkbox
                      :color="theme.color"
                      :input-value="active"
                    ></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.opd }}</v-list-item-title>
                    <v-list-item-subtitle>TGL. KIRIM{{ item.send_date}}</v-list-item-subtitle>
                    <v-list-item-subtitle>TGL. TERIMA{{ item.recieve_date}}</v-list-item-subtitle>

                  </v-list-item-content>
                  <v-list-item-action>
                    <v-menu
                      bottom
                      origin="center center"
                      transition="scale-transition"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          :color="theme.color"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-dots-vertical-circle-outline
                        </v-icon>
                      </template>

                      <v-list>
                        <v-list-item @click="postDeleteRecord(item.id)">
                          <v-list-item-title>
                            <v-icon color="red">mdi-delete</v-icon>
                            Hapus
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-list-item-action>

                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <v-col col="12">
      <v-dialog
        transition="dialog-bottom-transition"
        v-model="add"
        :max-width="device.desktop ? `600px` : `100%`"
        persistent
      >
        <v-card>
          <v-toolbar
            :color="theme.color"
            dark
          >Formulir Pengiriman Draft Surat</v-toolbar>

          <v-card-text>
            <v-col cols="12">
              <v-autocomplete
                label="OPD/SKPD"
                :color="theme.color"
                v-model="record.kunkers"
                outlined
                dense
                hide-details
                multiple
                :items="refunkerjas"
              ></v-autocomplete>
            </v-col>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-end">
            <v-btn
              outlined
              color="grey"
              @click="add = false"
            >Batal</v-btn>
            <v-btn
              outlined
              :color="theme.color"
              @click="postAddNewRecord"
            >Kirim</v-btn>
            <v-btn
              outlined
              :color="theme.color"
              v-show="edit"
              @click="postAddNewRecord"
            >Ubah</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "kirim-surat-keluar",
  data: () => ({
    num: 1,
    headers: [
      { text: "Tujuan/OPD", value: "opd" },
      { text: "Tgl. Kirim", value: "send_date", width: 200 },
      {
        text: "Tgl. Diterima",
        value: "recieve_date",
        align: "center",
        width: 200,
      },
      {
        text: "Aksi",
        value: "id",
        align: "center",
        width: 85,
        sortable: false,
      },
    ],

    search: null,
    lampiran: null,

    add: false,
    edit: false,

    formdraft: {
      show: false,
    },

    pejabats: [],

    refunkerjas: [],
  }),
  computed: {
    ...mapState([
      "page",
      "theme",
      "http",
      "device",
      "record",
      "records",
      "loading",
      "event",
      "snackbar",
    ]),
    filterItems() {
      if (this.search != null) {
        return this.records.filter((item) => {
          return (
            item.perihal.toLowerCase().indexOf(this.search.toLowerCase()) > -1
          );
        });
      } else {
        return this.records;
      }
    },
  },
  created() {
    this.setPage({
      crud: true,
      dataUrl: "api/operator/kirim-surat/" + this.$route.params.id,
      title: "KIRIM SURAT KELUAR",
      subtitle: "Berikut adalah seluruh kirim surat keluar yang tersedia",
      breadcrumbs: [
        {
          text: "Surat Keluar",
          disabled: false,
          href: "",
        },
        {
          text: "Kirim",
          disabled: true,
          href: "",
        },
      ],
      add: false,
      edit: false,
      actions: {
        refresh: true,
        add: true,
        edit: true,
        delete: true,
        bulkdelete: false,
        print: false,
        export: false,
        help: false,
      },
    });
    this.fetchRecords();
    this.fetchRefUnkerja();
  },
  mounted() {},
  methods: {
    ...mapActions([
      "setPage",
      "fetchRecords",
      "postAddNew",
      "postEdit",
      "postUpdate",
      "postConfirmDelete",
      "assignFileBrowse",
      "setRecord",
      "setLoading",
    ]),
    openAddNew: function () {
      this.add = true;
    },
    postAddNewRecord: function () {
      this.postAddNew(this.record).then(() => {
        this.fetchRecords();
        this.add = false;
      });
    },
    postAddNewRecordAllOpd: function () {
      this.record.seluruh_opd = true;
      this.postAddNew(this.record).then(() => {
        this.fetchRecords();
        this.add = false;
      });
    },

    editRecord: function (val) {
      this.$router.push({
        name: "operator-verifikasi-ak-satu-create",
        params: { id: val },
      });
    },

    postUpdateRecord: function () {
      this.postUpdate(this.record).then(() => {
        this.fetchRecords();
        this.path = "";
        this.add = false;
        this.edit = false;
      });
    },

    postDeleteRecord: function (val) {
      this.postConfirmDelete(val);
    },

    postDeleteAllRecord: async function () {
      try {
        this.setLoading({
          dialog: true,
          text: "Proses membatalkan seluruh kiriman",
        });
        let {
          data: { status, message },
        } = await this.http.post(
          "api/operator/kirim-surat-reset/" + this.$route.params.id
        );

        if (!status) {
          this.snackbar.color = "red";
          this.snackbar.text = message;
          this.snackbar.state = true;
          return;
        }

        this.snackbar.color = this.theme.color;
        this.snackbar.text = message;
        this.snackbar.state = true;
        this.fetchRecords();
      } catch (error) {
        this.snackbar.color = "red";
        this.snackbar.text = error;
        this.snackbar.state = true;
      } finally {
        this.setLoading({ dialog: false, text: "" });
      }
    },

    fetchRefUnkerja: async function () {
      let { data: data } = await this.http.post(
        "api/master-data/combo-unkerja"
      );
      this.refunkerjas = data;
    },

    fetchPejabat: async function () {
      let { data: data } = await this.http.post(
        "api/master-data/combo-pejabat"
      );
      this.pejabats = data;
    },

    uploadFile: function () {
      this.assignFileBrowse({
        fileType: [".jpeg", ".jpg", ".png"],
        query: {
          doctype: "storage",
        },
        callback: (response) => {
          setTimeout(() => {
            this.lampiran = response.name;
            this.record.lampiran = response.name;
          }, 500);
        },
      });
    },

    postDownload(val) {
      window.open(val, "__blank");
    },
  },
};
</script>
