<template>
  <v-container
    fluid
    class="pt-10 grid-list-xl"
  >
    <v-row>
      <div class="ml-5 mb-10 font-weight-bold black--text">
        Selamat Datang, {{ user.name.toUpperCase() }}
        <div class="font-weight-regular grey--text">
          Inilah dashboard aplikasi anda
        </div>
      </div>
    </v-row>

    <v-row class="justify-center mb-10 pl-5 pr-5">
      <v-layout
        column
        wrap
        class="my-2"
      >
        <v-flex
          xs12
          class="animated bounce"
        >
          <v-container grid-list-xl>
            <v-layout
              row
              wrap
              align-center
            >
              <v-flex
                xs12
                md3
              >
                <v-card
                  :class="`mx-auto elevation-10 dashboard-box-statistik`"
                  max-width="344"
                  outlined
                >
                  <v-list-item three-line>
                    <v-list-item-content>
                      <div class="overline mb-4 white--text">SURAT MASUK</div>
                      <v-list-item-title class="headline mb-1 white--text">
                        {{ jmlsuratmasuk }}
                      </v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-avatar
                      size="50"
                      class="elevation-2"
                      color="white + ` darken-1`"
                    >
                      <v-icon
                        :color="theme.color"
                        large
                      >mdi-inbox-arrow-down</v-icon>
                    </v-list-item-avatar>
                  </v-list-item>

                  <v-card-actions>
                    <v-btn
                      outlined
                      rounded
                      text
                      class="white--text"
                      @click="
                        $router.push({ name: 'operator-surat-masuk-index' })
                      "
                    >
                      Selengkapnya
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-flex>

              <v-flex
                xs12
                md3
              >
                <v-card
                  :class="`mx-auto elevation-10 dashboard-box-statistik`"
                  max-width="344"
                  outlined
                >
                  <v-list-item three-line>
                    <v-list-item-content>
                      <div class="overline mb-4 white--text">SURAT KELUAR</div>
                      <v-list-item-title class="headline mb-1 white--text">
                        {{ jmlsuratkeluar }}
                      </v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-avatar
                      size="50"
                      class="elevation-2"
                      color="white"
                    >
                      <v-icon
                        large
                        :color="theme.color"
                      >mdi-inbox-arrow-up</v-icon>
                    </v-list-item-avatar>
                  </v-list-item>

                  <v-card-actions>
                    <v-btn
                      outlined
                      rounded
                      text
                      class="white--text"
                      @click="
                        $router.push({ name: 'operator-surat-keluar-index' })
                      "
                    >
                      Selengkapnya
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-flex>
              <v-flex
                xs12
                md3
              >
                <v-card
                  :class="`mx-auto elevation-10 dashboard-box-statistik` "
                  max-width="344"
                  outlined
                  dark
                >
                  <v-list-item three-line>
                    <v-list-item-content>
                      <div class="overline mb-4 white--text">DRAFT MASUK</div>
                      <v-list-item-title class="headline mb-1 white--text">
                        {{ jmldraftmasuk }}
                      </v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-avatar
                      size="50"
                      class="elevation-2"
                      color="white"
                    >
                      <v-icon
                        :color="theme.color"
                        large
                      >mdi-email-receive</v-icon>
                    </v-list-item-avatar>
                  </v-list-item>

                  <v-card-actions>
                    <v-btn
                      outlined
                      rounded
                      text
                      dark
                      @click="$router.push({ name: 'user-draft-masuk' })"
                    >
                      Selengkapnya
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-flex>
              <v-flex
                xs12
                md3
              >
                <v-card
                  :class="`mx-auto elevation-10 dashboard-box-statistik` "
                  max-width="344"
                  outlined
                  dark
                >
                  <v-list-item three-line>
                    <v-list-item-content>
                      <div class="overline mb-4 white--text">DRAFT KELUAR</div>
                      <v-list-item-title class="headline mb-1 white--text">
                        {{ jmldraftkeluar }}
                      </v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-avatar
                      size="50"
                      class="elevation-2"
                      color="white"
                    >
                      <v-icon
                        :color="theme.color"
                        large
                      >mdi-email-send</v-icon>
                    </v-list-item-avatar>
                  </v-list-item>

                  <v-card-actions>
                    <v-btn
                      outlined
                      rounded
                      text
                      dark
                      @click="$router.push({ name: 'user-draft-keluar' })"
                    >
                      Selengkapnya
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-flex>
      </v-layout>
    </v-row>
    <v-row class="pl-9 pr-9"> </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";

import PeiPerusahaan from "../../chart/PeiPerusahaan.vue";

export default {
  name: "operator-dashboard",

  components: {
    PeiPerusahaan,
  },

  data: () => ({
    num: 1,
    datacollection: null,
    chartData: {
      Books: 24,
      Magazine: 30,
      Newspapers: 10,
    },

    jmlsuratmasuk: 0,
    jmlsuratkeluar: 0,
    jmldraftmasuk: 0,
    jmldraftkeluar: 0,

    jmlformasi: 0,
    jmlpelamar: 0,
    pelamars: [],
    pesans: [],

    search: null,
    jmlpegawai: 25,

    selected: [2],

    headers: [],

    headers2: [
      { text: "#", value: "num" },
      {
        text: "PROGRAM KEAHLIAN",
        align: "start",
        sortable: false,
        value: "jurusan",
      },
      { text: "BELUM TERVERIFIKASI", value: "jmlbelum" },
      { text: "SUDAH TERVERIFIKASI", value: "jmlsudah" },
    ],

    headers3: [
      { text: "#", value: "num" },
      {
        text: "PROGRAM KEAHLIAN",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "PILIHAN I", value: "jmlpil1" },
      { text: "PILIHAN II", value: "jmlpil2" },
    ],

    records: [],

    records2: [],

    records3: [],
  }),
  computed: {
    ...mapState(["theme", "http", "device", "loading", "event", "user"]),
  },
  created() {
    this.setPage({
      crud: true,
      dataUrl: "api/dashboard",
      pagination: false,
      title: "Dashboard",
      subtitle: "Gambaran keseluruhan data aplikasi yang anda miliki",
      breadcrumbs: [
        {
          text: "Dashboard",
          disabled: false,
          href: "",
        },
      ],
      add: false,
      edit: false,
      actions: {
        refresh: false,
        add: false,
        edit: false,
        delete: false,
        bulkdelete: false,
        print: false,
        export: false,
        help: false,
      },
    });
    this.fetchDashboard();
  },
  mounted() {},
  methods: {
    ...mapActions([
      "setPage",
      "fetchRecords",
      "postAddNew",
      "postEdit",
      "postUpdate",
      "postConfirmDelete",
    ]),

    fetchDashboard: async function () {
      let {
        data: { jmlsuratmasuk, jmlsuratkeluar, jmldraftmasuk, jmldraftkeluar },
      } = await this.http.get("api/operator/dashboard");

      this.jmlsuratmasuk = jmlsuratmasuk;
      this.jmlsuratkeluar = jmlsuratkeluar;
      this.jmldraftmasuk = jmldraftmasuk;
      this.jmldraftkeluar = jmldraftkeluar;
    },

    fetchPesan: async function () {
      let {
        data: { pesans },
      } = await this.http.get("api/dashboard-pesan");

      this.pesans = pesans;
    },

    fetchPerHari: async function () {
      let {
        data: { headers, records },
      } = await this.http.get("api/dashboard-perhari");

      this.headers = headers;
      this.records = records;
    },

    fethDataVerifikasi: async function () {
      let {
        data: { data },
      } = await this.http.get("api/dashboard-verifikasi");

      this.records2 = data;
    },

    fethDataPerjurusanPerPilihan: async function () {
      let {
        data: { datas },
      } = await this.http.get("api/dashboard-perjurusan-per-pilihan");

      this.records3 = datas;
    },
  },
};
</script>

<style>
.small {
  max-width: 600px;
  margin: 150px auto;
}
</style>
