<template>
  <div :class="device.desktop ? `home pa-6 grey lighten-4`:`home pa-0 grey lighten-4`">
    <v-row>
      <v-col col="12">
        <v-card>
          <v-card-title class="flex flex-row-reverse purple lighten-1">
            <v-tooltip
              color="green"
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  small
                  icon
                  v-on="on"
                >
                  <v-icon
                    color="white"
                    @click="fetchRecords"
                  >refresh</v-icon>
                </v-btn>
              </template>
              <span>Refresh Data</span>
            </v-tooltip>
            <v-tooltip
              :color="theme.color"
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  small
                  icon
                  v-on="on"
                  v-show="false"
                >
                  <v-icon
                    color="white"
                    @click="postAddNew"
                  >add_circle</v-icon>
                </v-btn>
              </template>
              <span>Tambah Surat Masuk Manual</span>
            </v-tooltip>

            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pencarian"
              single-line
              hide-details
              solo
              :color="theme.color"
            ></v-text-field>
          </v-card-title>
          <v-data-table
            v-show="device.desktop"
            :headers="headers"
            :items="records"
            :items-per-page="10"
            class="elevation-2"
            :color="theme.color"
            :loading="loading.table"
            loading-text="Loading... Please wait"
            :search="search"
            show-select
          >
            <v-progress-linear
              slot="progress"
              :color="theme.color"
              height="1"
              indeterminate
            ></v-progress-linear>

            <template v-slot:item.pengirim="{ value }">
              <span :class="value.bold">{{ value.pengirim }}</span>
            </template>

            <template v-slot:item.opsi="{ value }">
              <v-tooltip
                :color="value.color"
                bottom
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    text
                    small
                    icon
                    v-on="on"
                  >
                    <v-icon
                      :color="value.color"
                      @click="openSurat(value.id)"
                    >{{
                      value.icon
                    }}</v-icon>
                  </v-btn>
                </template>

                <span>Buka</span>
              </v-tooltip>
            </template>
          </v-data-table>
          <v-list
            subheader
            three-line
            v-show="device.mobile"
          >

            <v-list-item-group
              multiple
              active-class=""
            >
              <v-list-item
                v-for="item in filterItems"
                :key="item.id"
                style="border: ;1px solid"
              >
                <template v-slot:default="{ active }">
                  <v-list-item-action>
                    <v-checkbox
                      :color="theme.color"
                      :input-value="active"
                    ></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-subtitle>{{ item.tanggal_surat}}</v-list-item-subtitle>
                    <v-list-item-title><span :class="item.pengirim.bold">{{ item.pengirim.text }}</span></v-list-item-title>
                    <v-list-item-subtitle>{{ item.perihal}}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-menu
                      bottom
                      origin="center center"
                      transition="scale-transition"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          :color="theme.color"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-dots-vertical-circle-outline
                        </v-icon>
                      </template>

                      <v-list>
                        <v-list-item @click="openSurat(item.id)">
                          <v-list-item-title>
                            <v-icon color="blue">mdi-email-open</v-icon>
                            Buka
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-list-item-action>

                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
    </v-row>

    <v-col col="12">
      <v-dialog
        transition="dialog-bottom-transition"
        v-model="formteruskan.show"
        :max-width="device.desktop ? `500px` : `100%`"
        persistent
      >
        <v-card>
          <v-toolbar
            :color="theme.color"
            dark
          >Formulir Teruskan Surat</v-toolbar>

          <v-card-text>
            <v-col cols="12">
              <v-autocomplete
                label="Pejabat | Personal"
                :color="theme.color"
                outlined
                dense
                v-model="record.pejabats"
                hide-details
                :items="pejabats"
                multiple
              >
              </v-autocomplete>
            </v-col>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-end">
            <v-btn
              outlined
              :color="theme.color"
              @click="postDisposisi"
            >Kirim</v-btn>

            <v-btn
              outlined
              color="grey"
              @click="formteruskan.show = false"
            >Batal</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "surat-masuk ",
  data: () => ({
    num: 1,
    headers: [
      { text: "#", value: "num", align: "right", width: 50, sortable: false },
      { text: "Pengirim", value: "pengirim" },
      { text: "Perihal", value: "perihal" },
      { text: "Tgl. Surat", value: "tanggal_surat" },
      { text: "Tgl. Kirim", value: "tanggal_terima" },
      {
        text: "AKSI",
        value: "opsi",
        align: "center",
        width: 85,
        sortable: false,
      },
    ],

    search: null,
    lampiran: null,

    add: false,
    edit: false,

    formdraft: {
      show: false,
    },

    formteruskan: {
      show: false,
    },

    pejabats: [],
  }),
  computed: {
    ...mapState([
      "page",
      "theme",
      "http",
      "device",
      "record",
      "records",
      "loading",
      "event",
      "snackbar",
    ]),
    filterItems() {
      if (this.search != null) {
        return this.records.filter((item) => {
          return (
            item.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
          );
        });
      } else {
        return this.records;
      }
    },
  },
  created() {
    this.setPage({
      crud: true,
      dataUrl: "api/user/surat-masuk",
      pagination: false,
      title: "SURAT MASUK",
      subtitle: "Berikut adalah seluruh surat masuk yang tersedia",
      breadcrumbs: [
        {
          text: "Surat Masuk",
          disabled: false,
          href: "",
        },
      ],
      add: false,
      edit: false,
      actions: {
        refresh: true,
        add: true,
        edit: true,
        delete: true,
        bulkdelete: false,
        print: false,
        export: false,
        help: false,
      },
    });
    this.fetchRecords();
    this.fetchPejabat();
  },
  mounted() {},
  methods: {
    ...mapActions([
      "setPage",
      "fetchRecords",
      "postAddNew",
      "postEdit",
      "postUpdate",
      "postConfirmDelete",
      "assignFileBrowse",
      "setRecord",
      "setLoading",
    ]),
    fetchPejabat: async function () {
      let { data: data } = await this.http.post(
        "api/master-data/combo-pejabat"
      );
      this.pejabats = data;
    },
    openSurat: function (val) {
      this.$router.push({
        name: "user-surat-masuk-read",
        params: { id: val },
      });
    },
    openKirimSurat: function (val) {
      this.$router.push({
        name: "operator-surat-masuk-disposisi-index",
        params: { id: val },
      });
    },
    postAddNew: function () {
      this.$router.push({ name: "operator-surat-masuk-create" });
    },
    postEdit: function (val) {
      this.$router.push({
        name: "operator-surat-masuk-edit",
        params: { id: val },
      });
    },
    postDraftSurat: function (val) {
      this.$router.push({
        name: "operator-surat-keluar-draft",
        params: { id: val },
      });
    },

    postUpdateRecord: function () {
      this.postUpdate(this.record).then(() => {
        this.fetchRecords();
        this.path = "";
        this.add = false;
        this.edit = false;
      });
    },

    postDeleteRecord: function (val) {
      this.postConfirmDelete(val);
    },

    uploadFile: function () {
      this.assignFileBrowse({
        fileType: [".jpeg", ".jpg", ".png"],
        query: {
          doctype: "storage",
        },
        callback: (response) => {
          setTimeout(() => {
            this.lampiran = response.name;
            this.record.lampiran = response.name;
          }, 500);
        },
      });
    },

    postDownload(val) {
      window.open(val, "__blank");
    },
  },
};
</script>
