var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.device.desktop ? "home pa-6 grey lighten-4":"home pa-0 grey lighten-4"},[_c('v-row',[_c('v-col',{attrs:{"col":"12"}},[_c('v-card',{staticClass:"animated fadeInUp"},[_c('v-card-title',{staticClass:"flex flex-row-reverse purple lighten-1"},[_c('v-tooltip',{attrs:{"color":"green","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","small":"","icon":""}},on),[_c('v-icon',{attrs:{"color":"white"},on:{"click":_vm.fetchRecords}},[_vm._v("refresh")])],1)]}}])},[_c('span',[_vm._v("Refresh Data")])]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Pencarian","single-line":"","hide-details":"","solo":"","dense":"","color":_vm.theme.color},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(_vm.device.desktop),expression:"device.desktop"}],staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.records,"items-per-page":10,"color":_vm.theme.color,"loading":_vm.loading.table,"loading-text":"Loading... Please wait","search":_vm.search,"show-select":""},scopedSlots:_vm._u([{key:"item.sifat_surat",fn:function(ref){
var value = ref.value;
return [_c('v-icon',{attrs:{"color":value.color}},[_vm._v(_vm._s(value.icon))])]}},{key:"item.dokumen",fn:function(ref){
var value = ref.value;
return [_c('v-icon',{attrs:{"color":value.color},on:{"click":function($event){return _vm.postDownload(value.path)}}},[_vm._v("cloud_download")])]}},{key:"item.id",fn:function(ref){
var value = ref.value;
return [_c('v-menu',{attrs:{"bottom":"","origin":"center center","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":_vm.theme.color}},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-vertical-circle-outline ")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.postDraftSurat(value)}}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"color":"grey"}},[_vm._v("mdi-email-check")]),_vm._v(" Kirim Ke Draft Ke Pejabat ")],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.postKirimSurat(value)}}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-send")]),_vm._v(" Kirim Surat ")],1)],1),_c('v-divider'),_c('v-list-item',{on:{"click":function($event){return _vm.postEdit(value)}}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"color":"orange"}},[_vm._v("mdi-pencil-circle")]),_vm._v(" Edit ")],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.postDeleteRecord(value)}}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-delete-circle")]),_vm._v(" Hapus ")],1)],1)],1)],1)]}}])},[_c('v-progress-linear',{attrs:{"slot":"progress","color":_vm.theme.color,"height":"1","indeterminate":""},slot:"progress"})],1),_c('v-list',{directives:[{name:"show",rawName:"v-show",value:(_vm.device.mobile),expression:"device.mobile"}],attrs:{"subheader":"","three-line":""}},[_c('v-list-item-group',{attrs:{"multiple":"","active-class":""}},_vm._l((_vm.filterItems),function(item){return _c('v-list-item',{key:item.id,staticStyle:{"border":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"color":_vm.theme.color,"input-value":active}})],1),_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(_vm._s(item.tanggal_surat))]),_c('v-list-item-title',[_vm._v(_vm._s(item.opd))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.perihal))]),_c('v-list-item-subtitle')],1),_c('v-list-item-action',[_c('v-menu',{attrs:{"bottom":"","origin":"center center","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":_vm.theme.color}},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-vertical-circle-outline ")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.postDraftSurat(item.id)}}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"color":"grey"}},[_vm._v("mdi-email-check")]),_vm._v(" Kirim Ke Draft Ke Pejabat ")],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.postKirimSurat(item.id)}}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-send")]),_vm._v(" Kirim Surat ")],1)],1),_c('v-divider'),_c('v-list-item',{on:{"click":function($event){return _vm.postEdit(item.id)}}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"color":"orange"}},[_vm._v("mdi-pencil-circle")]),_vm._v(" Edit ")],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.postDeleteRecord(item.id)}}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-delete-circle")]),_vm._v(" Hapus ")],1)],1)],1)],1)],1)]}}],null,true)})}),1)],1)],1)],1)],1),_c('v-col',{attrs:{"col":"12"}},[_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":_vm.device.desktop ? "600px" : "100%","persistent":""},model:{value:(_vm.formdraft.show),callback:function ($$v) {_vm.$set(_vm.formdraft, "show", $$v)},expression:"formdraft.show"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":_vm.theme.color,"dark":""}},[_vm._v("Fromluir Pengiriman Draft Surat")]),_c('v-card-text'),_c('v-divider'),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"outlined":"","color":_vm.theme.color},on:{"click":_vm.postAddNewRecord}},[_vm._v("Tambah")]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.edit),expression:"edit"}],attrs:{"outlined":"","color":_vm.theme.color},on:{"click":_vm.postUpdateRecord}},[_vm._v("Ubah")]),_c('v-btn',{attrs:{"outlined":"","color":"grey"},on:{"click":function($event){_vm.add = false}}},[_vm._v("Batal")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }