<template>
  <v-app
    id="inspire"
    light
    v-cloak
    v-scroll="onScroll"
  >
    <v-app-bar
      :elevation="lightToolbar ? 0 : 6"
      :class="lightToolbar ? `transparent` : theme.color + ` darken-1`"
      fixed
    >
      <v-app-bar-nav-icon>
        <img
          class="ml-10"
          :src="info.app_logo"
          alt="logo"
          height="48"
        />
      </v-app-bar-nav-icon>

      <v-toolbar-title
        :class="{ 'pl-1': device.mobile }"
        v-if="!device.mobile"
      >
        <span class="font-weight-bold pl-5 white--text">{{
          info.app_company
        }}</span><br />
        <span class="text-xs pl-5 white--text">{{ info.app_slogan }}</span>
      </v-toolbar-title>

      <v-toolbar-title
        :class="{ 'pl-2': device.mobile }"
        v-if="device.mobile"
      >
        <span class="font-weight-bold pl-5 white--text">{{
          info.app_company
        }}</span>
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <v-toolbar-items class="pr-5">
        <v-btn
          text
          class="white--text"
          v-show="device.desktop"
        >BERANDA</v-btn>

        <!--<v-btn flat>Blog</v-btn>-->

        <v-btn
          text
          @click="$router.push({ name: 'login' })"
          class="white--text"
          v-show="device.desktop"
        >LOGIN</v-btn>
      </v-toolbar-items>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-show="device.mobile"
            color="white"
            icon
            light
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-title @click="$router.push({ name: 'home' })">
              Beranda
            </v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title @click="$router.push({ name: 'login' })">
              Login
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-main>
      <section>
        <v-carousel
          cycle
          :height="device.desktop ? `550` : `400`"
          hide-delimiters
          show-arrows-on-hover
        >
          <v-carousel-item
            v-for="(slide, i) in sliders"
            :key="i"
            :src="slide.path"
          >
            <v-row
              class="fill-height"
              align="center"
              justify="center"
            >
              <v-layout
                column
                align-center
                justify-center
                class="white--text"
              >
                <h1
                  class="white--text mb-2 display-1 text-xs-center"
                  style="font-weight: 900; text-shadow: 1px 2px #000000"
                >
                  {{ slide.title }}
                </h1>
                <div
                  class="white--text subheading mb-3 text-xs-center"
                  style="font-weight: 900; text-shadow: 1px 2px #000000"
                >
                  {{ slide.subtitle }}
                </div>

                <div
                  class="white--text subheading mb-3 text-xs-center"
                  style="font-weight: 900; text-shadow: 1px 2px #000000"
                >
                  {{ slide.body }}
                </div>
              </v-layout>
            </v-row>
          </v-carousel-item>
        </v-carousel>
        <!--
        <v-parallax
          v-for="slider in sliders"
          :key="slider.id"
          :src="slider.path"
        >
          <v-layout column align-center justify-center class="white--text">
            <h1
              class="white--text mb-2 display-1 text-xs-center"
              style="font-weight: 900; text-shadow: 3px 2px #000000"
            >
              SELAMAT DATANG
            </h1>
            <div
              class="white--text subheading mb-3 text-xs-center"
              style="font-weight: 900; text-shadow: 2px 2px #000000"
            >
              Laman Resmi Sistem Penerimaan Peserta Didik Baru
            </div>

            <div
              class="white--text subheading mb-3 text-xs-center"
              style="font-weight: 900; text-shadow: 2px 2px #000000"
            >
              Tahun Pelajaran 2021/2022
            </div>

            <div
              class="white--text subheading mb-3 text-xs -center mt-5"
              style="
                font-weight: 900;
                font-size: 15pt;
                text-shadow: 2px 2px #000000;
              "
              v-show="!info.apps_pendaftaran"
            >
              Mohon Maaf Pendaftaran Penerimaan Peserta Didik Baru Belum
              Dibuka...!
            </div>

            <v-btn
              :class="theme + ` darken-1 mt-5`"
              dark
              large
              @click="$router.push({ name: 'register' })"
              v-show="info.apps_pendaftaran"
            >
              DAFTAR SEKARANG
            </v-btn>
          </v-layout>
        </v-parallax>
        -->
      </section>
      <section class="animated bounce">
        <v-layout
          column
          wrap
          class="my-5"
          align-center
        >
          <v-flex xs12>
            <v-container grid-list-xl>
              <v-layout
                row
                wrap
                align-center
              >
                <v-flex
                  xs12
                  md4
                >
                  <v-card
                    :class="
                      `mx-auto elevation-10 box-statistik`
                    "
                    max-width="344"
                    outlined
                  >
                    <v-list-item three-line>
                      <v-list-item-content>
                        <div class="overline mb-4 white--text">SURAT MASUK</div>
                        <v-list-item-title class="headline mb-1 white--text">
                          {{ jmlsuratmasuk }}
                        </v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-avatar
                        size="50"
                        class="elevation-2"
                        color="white darken-1"
                      >
                        <v-icon
                          :color="theme.color"
                          large
                        >mdi-inbox-arrow-down</v-icon>
                      </v-list-item-avatar>
                    </v-list-item>

                    <v-card-actions>
                      <v-btn
                        outlined
                        rounded
                        text
                        class="white--text"
                      >
                        Dokumen
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-flex>
                <v-flex
                  xs12
                  md4
                >
                  <v-card
                    :class="
                      `mx-auto elevation-10 box-statistik`
                    "
                    max-width="344"
                    outlined
                  >
                    <v-list-item three-line>
                      <v-list-item-content>
                        <div class="overline mb-4 white--text">
                          SURAT KELUAR
                        </div>
                        <v-list-item-title class="headline mb-1 white--text">
                          {{ jmlsuratkeluar }}
                        </v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-avatar
                        size="50"
                        class="elevation-2"
                        color="white"
                      >
                        <v-icon
                          :color="theme.color"
                          large
                        >mdi-inbox-arrow-up</v-icon>
                      </v-list-item-avatar>
                    </v-list-item>

                    <v-card-actions>
                      <v-btn
                        outlined
                        rounded
                        text
                        class="white--text"
                      >
                        Dokumen
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-flex>
                <v-flex
                  xs12
                  md4
                >
                  <v-card
                    :class="
                      `mx-auto elevation-10 box-statistik `
                    "
                    max-width="344"
                    outlined
                    dark
                  >
                    <v-list-item three-line>
                      <v-list-item-content>
                        <div class="overline mb-4 white--text">
                          TOTAL DOKUMEN
                        </div>
                        <v-list-item-title class="headline mb-1 white--text">
                          {{ jmltotal }}
                        </v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-avatar
                        size="50"
                        class="elevation-2"
                        color="white"
                      >
                        <v-icon
                          :color="theme.color"
                          large
                        >mdi-inbox-full</v-icon>
                      </v-list-item-avatar>
                    </v-list-item>

                    <v-card-actions>
                      <v-btn
                        outlined
                        rounded
                        text
                        dark
                      > Dokumen </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-container>
          </v-flex>
        </v-layout>
      </section>
      <!--
      <section :class="theme.color + ` lighten-5`">
        <v-layout
          column
          wrap
          :class="`my-5 ` + theme.color + `  lighten-5 mb-0 mt-0`"
          align-center
        >
          <v-flex
            xs12
            sm4
            class="my-3"
          >
            <div class="text-xs-center text-center">
              <h2 class="headline">INFORMASI SIMPATIK</h2>
              <span class="subheading">{{ pengumuman.title }} </span>
            </div>
          </v-flex>
          <v-flex xs12>
            <v-container grid-list-xl>
              <v-layout
                row
                wrap
                align-center
              >
                <center>
                  {{ pengumuman.body }}
                </center>
                <br />
                <br />
              </v-layout>
            </v-container>
          </v-flex>
        </v-layout>
      </section>
      -->
    </v-main>
    <section class="footer">
      <v-layout
        column
        wrap
        class="footer"
      >
        <v-col cols=12>

          <v-row class="mr-10 ml-10">
            <v-col :cols="device.desktop ? `4` : `12`">
              <div class="title">DINAS KOMUNIKASI DAN INFORMATIKA </div>
              <div class="content">
                Jln. H. Somawinata Nomor 1, Desa Kadu Agung
              </div>
              <div class="content">
                Kabupaten Tangerang (15119) Prov. Banten
              </div>

              <div class="content">
                Telpon : (021) 599503
              </div>
            </v-col>
            <v-col :cols="device.desktop ? `4` : `12`">

            </v-col>
            <v-col :cols="device.desktop ? `4` : `12`">
              <div class="title">IKUTI KAMI </div>
              <div class="social-media">
                <v-icon color="white">mdi-facebook</v-icon>
                facebook@diskominfo
              </div>
              <div class="social-media">
                <v-icon color="white">mdi-instagram</v-icon>
                ig@diskominfo
              </div>
              <div class="social-media">
                <v-icon color="white">mdi-twitter</v-icon>
                tweeter@diskominfo
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-flex class="ml-10 mr-10 mt-2 pa-2 my-2 white--text align-center">
          <v-col cols=12>
            <div class="copyright ">
              {{ info.app_company }}

              &copy; 2021 Hak Cipta Dilindungi Undang-Undang.
            </div>

          </v-col>
        </v-flex>
      </v-layout>
    </section>

    <v-snackbar
      v-model="snackbar.state"
      :color="snackbar.color"
      :timeout="3500"
    >
      {{ snackbar.text }}
      <v-btn
        dark
        text
        @click.stop="snackbarClose"
      >Tutup</v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapState, mapActions } from "vuex";

import "animate.css";

export default {
  name: "Login",
  data: () => ({
    uname: "",
    upass: "",
    visible: false,
    color: "purple darken-3",
    jmlsuratmasuk: 0,
    jmlsuratkeluar: 0,
    jmltotal: 0,

    pengumuman: {
      title: null,
      body: null,
    },
    sliders: [],

    lightToolbar: true,
  }),
  mounted() {
    this.setPage({
      crud: true,
    }).then(() => {
      this.getAppInfo().then(() => {
        window.document.title = this.info.app_company;
        const favicon = window.document.getElementById("favicon");
        favicon.href = this.info.app_logo;
      });
    });
    this.fetchDashboard();
    this.fetchSliders();
    this.fetchPengumuman();
    this.fetchSummarydata();
  },
  computed: {
    ...mapState(["device", "info", "snackbar", "device", "http", "theme"]),

    contentStyle: function () {
      if (!this.device.mobile && this.info.apps_background) {
        return `background: url(${this.info.apps_background});
                        background-position: center center;
                        background-repeat: no-repeat;
                        background-size: cover;`;
      }
      return ``;
    },
  },
  methods: {
    ...mapActions([
      "setPage",
      "getAppInfo",
      "signIn",
      "snackbarClose",
      "getAppInfo",
    ]),
    onScroll: function (event) {
      if (window.pageYOffset >= window.innerHeight - 350) {
        if (this.lightToolbar === true) this.lightToolbar = false;
      } else {
        if (this.lightToolbar === false) this.lightToolbar = true;
      }
    },
    postAuthent: function () {
      this.signIn({
        username: this.uname.replace(/ /g, ""),
        userpass: this.upass,
      }).then((pass) => {
        if (!pass) {
          console.clear();
          return;
        }
        try {
          this.$router.push({ name: "dashboard" });
        } catch (error) {}
      });
    },
    postRegister: function () {
      this.$router.push({ name: "register" });
    },
    fetchDashboard: async function () {
      let {
        data: { jmlformasi, jmlpelamar },
      } = await this.http.get("api/dashboard-index");
      this.jmlformasi = jmlformasi;
      this.jmlpelamar = jmlpelamar;
    },
    fetchPengumuman: async function () {
      let { data } = await this.http.get("pengumuman");

      this.pengumuman = data;
    },
    fetchSliders: async function () {
      let { data } = await this.http.get("sliders");

      this.sliders = data;
    },
    fetchSummarydata: async function () {
      let {
        data: { jmlsuratmasuk, jmlsuratkeluar, jmltotal },
      } = await this.http.get("summarydata");

      this.jmlsuratmasuk = jmlsuratmasuk;
      this.jmlsuratkeluar = jmlsuratkeluar;
      this.jmltotal = jmltotal;
    },
  },
};
</script>

<style lang="sass">
@import '../../sass/card'
@import '../../sass/moui.sass'
@import '../../sass/textField.sass'
@import "../../assets/css/style.css"
@import "../../assets/css/wow.css"
</style>
