import Vue from "vue";
import VueRouter from "vue-router";
import Auth from "../providers/AuthProviders";
import Home from "../views/auth/Home.vue";

import Login from "../views/auth/Login.vue";
import Register from "../views/auth/Register.vue";
import Verify from "../views/auth/Verify.vue";
import VerifyByNip from "../views/auth/VerifyByNip.vue";
import VerifyBupati from "../views/auth/Bupati.vue";
import VerifyWakilBupati from "../views/auth/WkBupati.vue";
import VerifyKetuaDewan from "../views/auth/KetuaDewan.vue";
import VerifySekda from "../views/auth/Sekda.vue";

import Dashboard from "../views/backend/pages/Dashboard.vue";

import Base from "../views/backend/Base.vue";

import User from "../views/backend/pages/User.vue";

//page master data
import MasterAppInfo from "../views/backend/pages/masterdata/appinfo/Index.vue";
import MasterDokumen from "../views/backend/pages/masterdata/dokumen/Index.vue";
import MasterGroupJenisSurat from "../views/backend/pages/masterdata/klasifikasi/groupjenissurat/Index.vue";
import MasterJenisSurat from "../views/backend/pages/masterdata/klasifikasi/jenissurat/Index.vue";
import MasterOpd from "../views/backend/pages/masterdata/opd/Index.vue";
import MasterPegawai from "../views/backend/pages/masterdata/pegawai/Index.vue";

//Adminsitartor
import AdministratorRekapitulasi from "../views/backend/pages/administrator/rekapitulasi/Index.vue";
import AdministratorSuratMasukIndex from "../views/backend/pages/administrator/suratmasuk/Index.vue";
import AdministratorSuratMasukRead from "../views/backend/pages/administrator/suratmasuk/Read.vue";
import AdministratorSuratKeluarIndex from "../views/backend/pages/administrator/suratkeluar/Index.vue";

//Operator
import OperatorDashboard from "../views/backend/pages/operator/dashboard/Index.vue";
import OperatorPejabat from "../views/backend/pages/operator/pejabat/Index.vue";
import OperatorSuratKeluarIndex from "../views/backend/pages/operator/suratkeluar/Index.vue";
import OperatorSuratKeluarCreate from "../views/backend/pages/operator/suratkeluar/Create.vue";
import OperatorSuratKeluarEdit from "../views/backend/pages/operator/suratkeluar/Edit.vue";
import OperatorSuratKeluarDraft from "../views/backend/pages/operator/suratkeluar/Draft.vue";
import OperatorSuratKeluarKirim from "../views/backend/pages/operator/suratkeluar/Kirim.vue";
import OperatorSuratMasukIndex from "../views/backend/pages/operator/suratmasuk/Index.vue";
import OperatorSuratMasukCreate from "../views/backend/pages/operator/suratmasuk/Create.vue";
import OperatorSuratMasukEdit from "../views/backend/pages/operator/suratmasuk/Edit.vue";
import OperatorSuratMasukRead from "../views/backend/pages/operator/suratmasuk/Read.vue";
import OperatorSuratMasukDisposisiIndex from "../views/backend/pages/operator/suratmasuk/Disposisi.vue";
import OperatorLaporanSuratMasuk from "../views/backend/pages/operator/laporan/suratmasuk/Index.vue";
import OperatorLaporanSuratKeluar from "../views/backend/pages/operator/laporan/suratkeluar/Index.vue";

//User Pengguna
import UserDashboard from "../views/backend/pages/user/dashboard/Index.vue";
import UserDraftMasukIndex from "../views/backend/pages/user/approval/inbox/Index.vue";
import UserDraftKeluarIndex from "../views/backend/pages/user/approval/outbox/Index.vue";
import UserSuratMasuk from "../views/backend/pages/user/suratmasuk/Index.vue";
import UserSuratMasukRead from "../views/backend/pages/user/suratmasuk/Read.vue";
import UserDisposisi from "../views/backend/pages/user/disposisi/Index.vue";

//Backend Halaman Depan
import HalamanDepanSlider from "../views/backend/pages/halamandepan/sliders/Index.vue";
import HalamanDepanPengumuman from "../views/backend/pages/halamandepan/pengumuman/Index.vue";

//Utikity
import Changepwd from "../views/backend/pages/utility/Changepwd.vue";
import ProfilAkun from "../views/backend/pages/utility/Profil.vue";
import UtilityUserPerusahaan from "../views/backend/pages/utility/userperusahaan/Index.vue";
import UtilityUserPencaker from "../views/backend/pages/utility/userpencaker/Index.vue";
import UtilityEsignLog from "../views/backend/pages/utility/esignlog/Index.vue";
import UtilityManajemenFitur from "../views/backend/pages/utility/manajemenfitur/Index.vue";
import UtilityManajemenFiturAdministrator from "../views/backend/pages/utility/manajemenfitur/administrator/Index.vue";
import UtilityUpdateHistory from "../views/backend/pages/utility/updatehistory/Index.vue";
import UtilityUpdateHistoryItem from "../views/backend/pages/utility/updatehistory/items/Index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/register",
    name: "register",
    component: Register,
  },
  {
    path: "/verify/:surat_id",
    name: "verify",
    component: Verify,
  },
  {
    path: "/verify-document/:nip",
    name: "verifysk",
    component: VerifyByNip,
  },
  {
    path:
      "/verifikasi-dokumen/DFE312873440F42C691C6654860003BF865AD1D6B3577C5770CEE15D0F695C8C",
    name: "verifikasi-dokumen-bupati",
    component: VerifyBupati,
  },
  {
    path:
      "/verifikasi-dokumen/9D068E44B70814FC19E7729223B85D305BB3252487045F7FB0500D140C486558",
    name: "verifikasi-dokumen-wakil-bupati",
    component: VerifyWakilBupati,
  },
  {
    path:
      "/verifikasi-dokumen/3D24188B0F11888C382F32EA438706007BBA273C4EB80D23065B310C6F5D57DE",
    name: "verifikasi-dokumen-ketua-dewan",
    component: VerifyKetuaDewan,
  },
  {
    path:
      "/verifikasi-dokumen/3D24188B0F11888C382F32EA438706007BBA273C4EB80D23065B310C6F5D57DF",
    name: "verifikasi-dokumen-sekda",
    component: VerifySekda,
  },

  //backend route
  {
    path: "/backend",
    name: "",
    meta: {
      auth: true,
    },
    component: Base,
    children: [
      { path: "", redirect: { name: "dashboard" } },
      { path: "dashboard", name: "dashboard", component: Dashboard },
      { path: "user", name: "user", component: User },
      {
        path: "chngpwd",
        name: "chngpwd",
        component: Changepwd,
      },
      {
        path: "profil-akun",
        name: "profil-akun",
        component: ProfilAkun,
      },
      //route master data
      {
        path: "master-app-info",
        name: "master-app-info",
        component: MasterAppInfo,
      },
      {
        path: "master-dokumen",
        name: "master-dokumen",
        component: MasterDokumen,
      },
      {
        path: "master-group-jenis-surat",
        name: "master-group-jenis-surat",
        component: MasterGroupJenisSurat,
      },
      {
        path: "master-jenis-surat/:group_jenis_surat_id",
        name: "master-jenis-surat",
        component: MasterJenisSurat,
      },
      {
        path: "master-opd",
        name: "master-opd",
        component: MasterOpd,
      },
      {
        path: "master-pegawai",
        name: "master-pegawai",
        component: MasterPegawai,
      },

      /**
       * Adminstartor
       */
      {
        path: "administrator-rekapitulasi",
        name: "administrator-rekapitulasi",
        component: AdministratorRekapitulasi,
      },
      {
        path: "administrator-surat-masuk-index",
        name: "administrator-surat-masuk-index",
        component: AdministratorSuratMasukIndex,
      },
      {
        path: "administrator-surat-masuk-read/:id",
        name: "administrator-surat-masuk-read",
        component: AdministratorSuratMasukRead,
      },
      {
        path: "administrator-surat-keluar-index",
        name: "administrator-surat-keluar-index",
        component: AdministratorSuratKeluarIndex,
      },

      /**
       * Area Operator
       */
      {
        path: "operator-dashboard",
        name: "operator-dashboard",
        component: OperatorDashboard,
      },
      {
        path: "operator-pejabat",
        name: "operator-pejabat",
        component: OperatorPejabat,
      },
      {
        path: "operator-surat-keluar-index",
        name: "operator-surat-keluar-index",
        component: OperatorSuratKeluarIndex,
      },
      {
        path: "operator-surat-keluar-create",
        name: "operator-surat-keluar-create",
        component: OperatorSuratKeluarCreate,
      },
      {
        path: "operator-surat-keluar-edit/:id",
        name: "operator-surat-keluar-edit",
        component: OperatorSuratKeluarEdit,
      },
      {
        path: "operator-surat-keluar-draft/:id",
        name: "operator-surat-keluar-draft",
        component: OperatorSuratKeluarDraft,
      },
      {
        path: "operator-surat-keluar-kirim/:id",
        name: "operator-surat-keluar-kirim",
        component: OperatorSuratKeluarKirim,
      },

      //surat masuk
      {
        path: "operator-surat-masuk-index",
        name: "operator-surat-masuk-index",
        component: OperatorSuratMasukIndex,
      },
      {
        path: "operator-surat-masuk-create",
        name: "operator-surat-masuk-create",
        component: OperatorSuratMasukCreate,
      },
      {
        path: "operator-surat-masuk-edit/:id",
        name: "operator-surat-masuk-edit",
        component: OperatorSuratMasukEdit,
      },
      {
        path: "operator-surat-masuk-read/:id",
        name: "operator-surat-masuk-read",
        component: OperatorSuratMasukRead,
      },
      {
        path: "operator-surat-masuk-disposisi-index/:id",
        name: "operator-surat-masuk-disposisi-index",
        component: OperatorSuratMasukDisposisiIndex,
      },
      {
        path: "operator-laporan-surat-masuk",
        name: "operator-laporan-surat-masuk",
        component: OperatorLaporanSuratMasuk,
      },
      {
        path: "operator-laporan-surat-keluar",
        name: "operator-laporan-surat-keluar",
        component: OperatorLaporanSuratKeluar,
      },
      /**
       * Area User /Pengguna
       */
      {
        path: "user-dashboard",
        name: "user-dashboard",
        component: UserDashboard,
      },
      {
        path: "user-draft-masuk",
        name: "user-draft-masuk",
        component: UserDraftMasukIndex,
      },
      {
        path: "user-draft-keluar",
        name: "user-draft-keluar",
        component: UserDraftKeluarIndex,
      },
      {
        path: "user-surat-masuk",
        name: "user-surat-masuk",
        component: UserSuratMasuk,
      },
      {
        path: "user-surat-masuk-read/:id",
        name: "user-surat-masuk-read",
        component: UserSuratMasukRead,
      },
      {
        path: "user-disposisi",
        name: "user-disposisi",
        component: UserDisposisi,
      },

      /**
       * Area Utikity
       */
      {
        path: "utility-user-perusahaan",
        name: "utility-user-perusahaan",
        component: UtilityUserPerusahaan,
      },
      {
        path: "utility-user-pencaker",
        name: "utility-user-pencaker",
        component: UtilityUserPencaker,
      },
      {
        path: "utility-esign-log",
        name: "utility-esign-log",
        component: UtilityEsignLog,
      },
      {
        path: "utility-manajemen-fitur",
        name: "utility-manajemen-fitur",
        component: UtilityManajemenFitur,
      },
      {
        path: "utility-manajemen-fitur-administrator",
        name: "utility-manajemen-fitur-administrator",
        component: UtilityManajemenFiturAdministrator,
      },
      {
        path: "utility-update-history",
        name: "utility-update-history",
        component: UtilityUpdateHistory,
      },
      {
        path: "utility-update-history-items/:update_history_id",
        name: "utility-update-history-items",
        component: UtilityUpdateHistoryItem,
      },

      /**
       * Route Manajemen Halaman Depan
       */

      //Route slider
      {
        path: "halaman-depan-slider",
        name: "halaman_depan_slider",
        component: HalamanDepanSlider,
      },
      //Route Pengumuman
      {
        path: "halaman-depan-pengumuman",
        name: "halaman-depan-pengumuman",
        component: HalamanDepanPengumuman,
      },
    ],
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  mode: "history",
});

/**
 * router match
 */

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.auth);

  if (requiresAuth) {
    if (!Auth.check) {
      next({ name: "login" });
    } else {
      next();
    }
  } else {
    if (to.name === "login" && Auth.check) {
      if (Auth.user.authent == "operator") {
        next({ name: "operator-dashboard" });
      } else if (Auth.user.auth === "user") {
        next({ name: "user-dashboard" });
      } else {
        next({ name: "dashboard" });
      }
    } else {
      next();
    }
  }
});

export default router;
