<template>
  <div :class="device.desktop ? `home pa-6 grey lighten-4`:`home pa-0 grey lighten-4`">
    <v-row>
      <v-col col="12">
        <v-card class="animated fadeInUp">
          <v-card-title class="flex flex-row-reverse purple lighten-1">
            <v-tooltip
              :color="theme.color"
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  small
                  icon
                  v-on="on"
                  v-show="page.actions.add"
                >
                  <v-icon
                    :color="theme.mode == 'dark' ? `white` : `black`"
                    @click="postAddNew"
                  >add_circle</v-icon>
                </v-btn>
              </template>
              <span>Tambah Data</span>
            </v-tooltip>
            <v-tooltip
              :color="theme.color"
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  small
                  icon
                  v-on="on"
                >
                  <v-icon
                    :color="theme.mode == 'dark' ? `white` : `black`"
                    @click="fetchRecords"
                  >refresh</v-icon>
                </v-btn>
              </template>
              <span>Refresh Data</span>
            </v-tooltip>

            <v-spacer></v-spacer>

            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pencarian"
              single-line
              hide-details
              solo
              :color="theme.color"
              dense
            ></v-text-field>
          </v-card-title>
          <v-data-table
            v-show="device.desktop"
            :headers="headers"
            :items="records"
            :items-per-page="10"
            class="elevation-2"
            :color="theme.color"
            :loading="loading.table"
            loading-text="Loading... Please wait"
            :search="search"
            show-select
          >
            <v-progress-linear
              slot="progress"
              :color="theme.color"
              height="1"
              indeterminate
            ></v-progress-linear>
            <template v-slot:item.pengirim="{ value }">
              <span :class="value.bold">{{ value.text }}</span>
            </template>

            <template v-slot:item.sifat_surat="{ value }">
              <v-chip
                small
                :color="value.color"
              >{{ value.text }}</v-chip>
            </template>

            <template v-slot:item.dokumen="{ value }">
              <v-icon
                :color="value.color"
                @click="postDownload(value.path)"
              >cloud_download</v-icon>
            </template>

            <template v-slot:item.opsi="{ value }">
              <v-menu
                bottom
                origin="center center"
                transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    :color="theme.color"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-dots-vertical-circle-outline
                  </v-icon>
                </template>

                <v-list>
                  <v-list-item @click="openSurat(value.id)">
                    <v-list-item-title>
                      <v-icon color="blue">mdi-email-open</v-icon>
                      Buka
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="openKirimSurat(value.id)">
                    <v-list-item-title>
                      <v-icon color="green">mdi-send</v-icon>
                      Disposisi
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    :disabled="value.disabled"
                    @click="postEdit(value.id)"
                  >
                    <v-list-item-title>
                      <v-icon
                        :disabled="value.disabled"
                        color="orange"
                      >mdi-content-save-edit</v-icon>
                      Edit
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    :disabled="value.disabled"
                    @click="postDeleteRecord(value.id)"
                  >
                    <v-list-item-title>
                      <v-icon
                        :disabled="value.disabled"
                        color="red"
                      >mdi-delete</v-icon>
                      Hapus
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
          <v-list
            subheader
            three-line
            v-show="device.mobile"
          >

            <v-list-item-group
              multiple
              active-class=""
            >
              <v-list-item
                v-for="item in filterItems"
                :key="item.id"
                style="border: ;1px solid"
              >
                <template v-slot:default="{ active }">
                  <v-list-item-action>
                    <v-checkbox
                      :color="theme.color"
                      :input-value="active"
                    ></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-subtitle>{{ item.tanggal_kirim}}</v-list-item-subtitle>
                    <v-list-item-title><span :class="item.pengirim.bold">{{ item.pengirim.text }}</span></v-list-item-title>
                    <v-list-item-subtitle>{{ item.perihal}}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-menu
                      bottom
                      origin="center center"
                      transition="scale-transition"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          :color="theme.color"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-dots-vertical-circle-outline
                        </v-icon>
                      </template>

                      <v-list>
                        <v-list-item @click="openSurat(item.id)">
                          <v-list-item-title>
                            <v-icon color="blue">mdi-email-open</v-icon>
                            Buka
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="openKirimSurat(item.id)">
                          <v-list-item-title>
                            <v-icon color="green">mdi-send</v-icon>
                            Disposisi
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          :disabled="item.disabled"
                          @click="postEdit(item.id)"
                        >
                          <v-list-item-title>
                            <v-icon
                              :disabled="item.disabled"
                              color="orange"
                            >mdi-content-save-edit</v-icon>
                            Edit
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          :disabled="item.disabled"
                          @click="postDeleteRecord(item.id)"
                        >
                          <v-list-item-title>
                            <v-icon
                              :disabled="item.disabled"
                              color="red"
                            >mdi-delete</v-icon>
                            Hapus
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-list-item-action>

                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "surat-masuk",
  data: () => ({
    num: 1,
    headers: [
      { text: "Pengirim", value: "pengirim" },
      { text: "Sifat", value: "sifat_surat", align: "center" },
      { text: "Perihal", value: "perihal" },
      { text: "Tgl.Surat", value: "tanggal_surat", align: "center" },
      { text: "Tgl.Masuk", value: "tanggal_kirim", align: "center" },
      {
        text: "AKSI",
        value: "opsi",
        align: "center",
        width: 80,
        sortable: false,
      },
    ],

    search: null,
    lampiran: null,

    add: false,
    edit: false,

    formdraft: {
      show: false,
    },

    formteruskan: {
      show: false,
    },

    pejabats: [],
  }),
  computed: {
    ...mapState([
      "page",
      "theme",
      "http",
      "device",
      "record",
      "records",
      "loading",
      "event",
      "snackbar",
    ]),
    filterItems() {
      if (this.search != null) {
        return this.records.filter((item) => {
          return (
            item.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
          );
        });
      } else {
        return this.records;
      }
    },
  },
  created() {
    this.setPage({
      crud: true,
      dataUrl: "api/operator/surat-masuk",
      pagination: false,
      title: "SURAT MASUK",
      subtitle: "Berikut adalah seluruh surat masuk yang tersedia",
      breadcrumbs: [
        {
          text: "Surat Masuk",
          disabled: false,
          href: "",
        },
      ],
      add: false,
      edit: false,
      actions: {
        refresh: true,
        add: true,
        edit: true,
        delete: true,
        bulkdelete: false,
        print: false,
        export: false,
        help: false,
      },
    });
    this.fetchRecords();
    this.fetchPejabat();
  },
  mounted() {},
  methods: {
    ...mapActions([
      "setPage",
      "fetchRecords",
      "postAddNew",
      "postEdit",
      "postUpdate",
      "postConfirmDelete",
      "assignFileBrowse",
      "setRecord",
      "setLoading",
    ]),
    openForm: function () {
      this.setPage({
        add: true,
        edit: false,
      });
    },
    closeForm: function () {
      this.setPage({
        add: false,
        edit: false,
      });
    },
    fetchPejabat: async function () {
      let { data: data } = await this.http.post(
        "api/master-data/combo-pejabat"
      );
      this.pejabats = data;
    },
    openSurat: function (val) {
      this.$router.push({
        name: "operator-surat-masuk-read",
        params: { id: val },
      });
    },
    openKirimSurat: function (val) {
      this.$router.push({
        name: "operator-surat-masuk-disposisi-index",
        params: { id: val },
      });
    },
    postAddNew: function () {
      this.$router.push({ name: "operator-surat-masuk-create" });
    },
    postEdit: function (val) {
      this.$router.push({
        name: "operator-surat-masuk-edit",
        params: { id: val },
      });
    },
    postDraftSurat: function (val) {
      this.$router.push({
        name: "operator-surat-keluar-draft",
        params: { id: val },
      });
    },

    postUpdateRecord: function () {
      this.postUpdate(this.record).then(() => {
        this.fetchRecords();
        this.path = "";
        this.add = false;
        this.edit = false;
      });
    },

    postDeleteRecord: function (val) {
      this.postConfirmDelete(val);
    },

    uploadFile: function () {
      this.assignFileBrowse({
        fileType: [".jpeg", ".jpg", ".png"],
        query: {
          doctype: "storage",
        },
        callback: (response) => {
          setTimeout(() => {
            this.lampiran = response.name;
            this.record.lampiran = response.name;
          }, 500);
        },
      });
    },

    postDownload(val) {
      window.open(val, "__blank");
    },
  },
};
</script>
