<template>
  <div :class="device.desktop ? `home pa-6 grey lighten-4`:`home pa-0 grey lighten-4`">
    <v-row class="pa-1">
      <v-spacer></v-spacer>
      <v-col :cols="device.desktop ? `12` : `12`">
        <v-card
          class="animated fadeInUp"
          style="height: 100vh"
        >
          <v-card-title :class="`white--text ` + theme.color + ` Plighten-1`">
            <v-row class="justify-end mr-2">
              <v-tooltip
                :color="theme.color"
                bottom
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    text
                    small
                    icon
                    v-on="on"
                  >
                    <v-icon
                      color="white"
                      @click="openDisposisi"
                    >mdi-email-send</v-icon>
                  </v-btn>
                </template>
                <span>Disposisi</span>
              </v-tooltip>
              <v-tooltip
                :color="theme.color"
                bottom
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    text
                    small
                    icon
                    v-on="on"
                  >
                    <v-icon
                      color="white"
                      @click="
                        $router.push({ name: 'operator-surat-masuk-index' })
                      "
                    >cancel</v-icon>
                  </v-btn>
                </template>
                <span>Kembali</span>
              </v-tooltip>
            </v-row>
          </v-card-title>

          <v-divider></v-divider>

          <iframe
            :src="record.pdf_path"
            frameborder="0"
            width="100%"
            height="100%"
          ></iframe>
        </v-card>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-col col="12">
      <v-dialog
        transition="dialog-bottom-transition"
        v-model="formteruskan.show"
        :max-width="device.desktop ? `500px` : `100%`"
        persistent
      >
        <v-card>
          <v-toolbar
            :color="theme.color"
            dark
          >Formulir Disposisi</v-toolbar>

          <v-card-text>
            <v-col cols="12">
              <v-autocomplete
                label="Pejabat | Personal"
                :color="theme.color"
                outlined
                dense
                v-model="record.pejabats"
                hide-details
                :items="pejabats"
                multiple
              >
              </v-autocomplete>
            </v-col>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-end">
            <v-btn
              outlined
              :color="theme.color"
              @click="postDisposisi"
            >Kirim</v-btn>

            <v-btn
              outlined
              color="grey"
              @click="formteruskan.show = false"
            >Batal</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "perusahaan-index",
  data: () => ({
    foto: "/",
    formteruskan: {
      show: false,
    },
    pejabats: [],
  }),
  computed: {
    ...mapState([
      "theme",
      "http",
      "device",
      "records",
      "record",
      "loading",
      "event",
      "snackbar",
    ]),
  },
  created() {
    this.setPage({
      crud: true,
      dataUrl: "api/",
      pagination: false,
      title: "BACA SURAT MASUK",
      subtitle: "Formulir Baca Surat Masuk",
      breadcrumbs: [
        {
          text: "Surat Masuk",
          disabled: false,
          href: "",
        },
        {
          text: "Baca Surat Masuk",
          disabled: true,
          href: "",
        },
      ],
      add: false,
      edit: false,
      actions: {
        refresh: true,
        add: true,
        edit: true,
        delete: true,
        bulkdelete: false,
        print: false,
        export: false,
        help: false,
      },
    });

    this.fetchRecord();
    this.fetchPejabat();
  },
  mounted() {},
  methods: {
    ...mapActions([
      "setPage",
      "assignFileBrowse",
      "getUserInfo",
      "initUploadLibrary",
      "setRecord",
      "setLoading",
    ]),
    fetchRecord: async function () {
      let { data } = await this.http.get(
        "api/operator/surat-masuk/" + this.$route.params.id
      );

      let { datas } = await this.http.post(
        "api/operator/set-as-read/" + this.$route.params.id
      );

      this.setRecord(data);
    },
    fetchPejabat: async function () {
      let { data: data } = await this.http.post(
        "api/master-data/combo-pejabat"
      );
      this.pejabats = data;
    },
    openDisposisi: function () {
      this.formteruskan.show = true;
    },
    postDisposisi: async function () {
      try {
        this.setLoading({ dialog: true, text: "Proses kirim disposisi" });
        let {
          data: { status, message },
        } = await this.http.post(
          "api/operator/surat-masuk-disposisi/" + this.$route.params.id,
          this.record
        );

        if (!status) {
          this.snackbar.color = "red";
          this.snackbar.text = message;
          this.snackbar.state = true;
          return;
        }

        this.snackbar.color = this.theme.color;
        this.snackbar.text = message;
        this.snackbar.state = true;
      } catch (error) {
        this.snackbar.color = "red";
        this.snackbar.text = "Opps..., terjadi kesalahan " + error;
        this.snackbar.state = true;
      } finally {
        this.setLoading({ dialog: false, text: "" });
        this.formteruskan.show = false;
      }
    },
    fetchUpdate: async function () {
      try {
        let {
          data: { status, message, data },
        } = await this.http.post("api/update-profil", this.record);

        if (!status) {
          this.snackbar.color = "red";
          this.snackbar.text = message;
          this.snackbar.state = true;
        }

        this.snackbar.color = this.theme.color;
        this.snackbar.text = message;
        this.snackbar.state = true;

        this.record = data;
        this.fetchRecord();
        this.getUserInfo();
      } catch (error) {
        this.snackbar.color = "red";
        this.snackbar.text = error;
        this.snackbar.state = true;
      }
    },

    uploadFoto: function () {
      this.assignFileBrowse({
        fileType: [".jpg", ".jpeg", ".png"],
        query: {
          doctype: "avatar",
        },
        callback: (response) => {
          setTimeout(() => {
            this.foto = response.path;
            this.record.avatar = response.name;
          }, 1000);
        },
      });
    },
  },
};
</script>

<style></style>
