<template>
  <v-app
    id="inspire"
    v-cloak
  >
    <v-row :class="device.desktop ? `justify center ma-10` : `justify center ma-1`">
      <v-col cols="device.desktop ? 6 : 12">
        <v-card
          class="mx-auto"
          max-width="500"
        >
          <v-img
            class="white--text align-end"
            height="100px"
            :src="info.app_background"
          >
            <v-card-title>VERIFIKASI DATA</v-card-title>
          </v-img>
          <v-card-text>
            <v-row>
              <v-col cols="4">
                <v-img :src="verify.foto"></v-img>
              </v-col>
              <v-col cols="8">
                <div :style="device.desktop ? `` : `font-size:8pt`">
                  Telah ditandatangani secara elekronik
                </div>
                <div :style="device.desktop ? `` : `font-size:8pt`">
                  {{ verify.jabatan }}
                </div>
                <div style="font-weight: bold">{{ verify.name }}</div>
                <div>NIP. {{ verify.nip }}</div>
                <div>
                  <v-row class="mt-1">
                    <div></div>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "Login",
  data: () => ({
    uname: "",
    upass: "",
    visible: false,
    verify: {},
  }),
  mounted() {
    this.setPage({
      crud: true,
    }).then(() => {
      this.getAppInfo().then(() => {
        window.document.title = this.info.app_name;
        const favicon = window.document.getElementById("favicon");
        favicon.href = this.info.app_logo;
      });
    });
    this.postVerify();
  },
  created() {},
  computed: {
    ...mapState(["device", "info", "snackbar", "auth", "http", "theme"]),

    contentStyle: function () {
      if (!this.device.mobile && this.info.app_background) {
        return `background: url(${this.info.app_background});
                        background-position: center center;
                        background-repeat: no-repeat;
                        background-size: cover;`;
      }
      return ``;
    },
  },
  methods: {
    ...mapActions([
      "setPage",
      "getAppInfo",
      "signIn",
      "snackbarClose",
      "clearMenu",
      "getMenus",
    ]),
    postVerify: async function () {
      let { data } = await this.http.get("verifysk/" + this.$route.params.nip);
      this.verify = data;
    },
  },
};
</script>

<style lang="sass">
@import '../../sass/card'
@import '../../sass/moui.sass'
@import '../../sass/textField.sass'
</style>
