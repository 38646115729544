<template>
  <div :class="device.desktop ? `home pa-6 grey lighten-4`:`home pa-0 grey lighten-4`">
    <v-row>
      <v-col col="12">
        <v-card class="animated animate__bounceIn">
          <v-card-title :class="`flex flex-row-reverse ` + colors.titlebar ">
            <v-tooltip
              :color="theme.color"
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  small
                  icon
                  v-on="on"
                >
                  <v-icon
                    color="white"
                    @click="downloadExcel"
                  >mdi-file-excel</v-icon>
                </v-btn>
              </template>
              <span>Export Data</span>
            </v-tooltip>
            <v-tooltip
              :color="theme.color"
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  small
                  icon
                  v-on="on"
                >
                  <v-icon
                    color="white"
                    @click="printReport"
                  >mdi-printer</v-icon>
                </v-btn>
              </template>
              <span>Cetak Data</span>
            </v-tooltip>
            <v-tooltip
              :color="theme.color"
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  small
                  icon
                  v-on="on"
                >
                  <v-icon
                    color="white"
                    @click="fetchSearch"
                  >refresh</v-icon>
                </v-btn>
              </template>
              <span>Refresh Data</span>
            </v-tooltip>

            <v-spacer></v-spacer>
            <v-col :cols="device.desktop ? `3` : `12`">
              <v-dialog
                ref="dialog2"
                v-model="modal2"
                :return-value.sync="record.bulan2"
                persistent
                width="290px"
                dense
                hide-details
                :color="colors.secondary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="bulan2"
                    label="S.d Bulan"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    solo
                    :color="colors.secondary"
                    dense
                    hide-details
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="bulan2"
                  :color="colors.secondary"
                  type="month"
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="grey"
                    @click="modal2 = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    :color="colors.secondary"
                    @click="$refs.dialog2.save(bulan2)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>

              </v-dialog>
            </v-col>
            <v-col :cols="device.desktop ? `3` : `12`">
              <v-dialog
                ref="dialog1"
                v-model="modal1"
                :return-value.sync="record.bulan1"
                persistent
                width="290px"
                dense
                hide-details
                :color="colors.secondary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="bulan1"
                    label="Dari Bulan"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    solo
                    color="white"
                    dense
                    hide-details
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="bulan1"
                  :color="colors.secondary"
                  type="month"
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="grey"
                    @click="modal1 = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    :color="theme.color"
                    @click="$refs.dialog1.save(bulan1)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>

              </v-dialog>
            </v-col>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pencarian"
              single-line
              hide-details
              dense
              solo
              :color="colors.secondary"
            ></v-text-field>
          </v-card-title>
          <v-data-table
            v-show="device.desktop"
            :headers="headers"
            :items="records"
            :items-per-page="10"
            class="elevation-2"
            :color="theme.color"
            :loading="loading.table"
            loading-text="Loading... Please wait"
            :search="search"
          >
            <v-progress-linear
              slot="progress"
              :color="theme.color"
              height="1"
              indeterminate
            ></v-progress-linear>
          </v-data-table>
          <v-list
            subheader
            three-line
            v-show="device.mobile"
          >

            <v-list-item-group
              multiple
              active-class=""
            >
              <v-list-item
                v-for="item in filterItems"
                :key="item.id"
                style="border: ;1px solid"
              >
                <template v-slot:default="{ active }">
                  <v-list-item-action>
                    <v-checkbox
                      :color="theme.color"
                      :input-value="active"
                    ></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.nunker }}</v-list-item-title>
                    <v-list-item-subtitle>Surat Masuk : {{ item.jmlsk }}</v-list-item-subtitle>
                    <v-list-item-subtitle>Surat Keluar : {{ item.jmlsm }}</v-list-item-subtitle>
                    <v-list-item-subtitle>Total : {{ item.total }}</v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
    </v-row>

    <v-col col="12">
      <v-dialog
        transition="dialog-bottom-transition"
        v-model="formteruskan.show"
        :max-width="device.desktop ? `500px` : `100%`"
        persistent
      >
        <v-card>
          <v-toolbar
            :color="theme.color"
            dark
          >Formulir Teruskan Surat</v-toolbar>

          <v-card-text>
            <v-col cols="12">
              <v-autocomplete
                label="Pejabat | Personal"
                :color="theme.color"
                outlined
                dense
                v-model="record.pejabats"
                hide-details
                :items="pejabats"
                multiple
              >
              </v-autocomplete>
            </v-col>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-end">
            <v-btn
              outlined
              :color="theme.color"
            >Kirim</v-btn>

            <v-btn
              outlined
              color="grey"
              @click="formteruskan.show = false"
            >Batal</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import jsPDF from "jspdf";
import XLSX from "xlsx";

export default {
  name: "Home",
  data: () => ({
    num: 1,
    headers: [
      { text: "#", value: "num", align: "right" },
      { text: "OPD/SKPD", value: "nunker" },
      { text: "JML.SURAT KELUAR", value: "jmlsk", align: "right", width: 200 },
      { text: "JML.SURAT MASUK", value: "jmlsm", align: "right", width: 200 },
      { text: "TOTAL", value: "total", align: "right" },
    ],

    bulan1: null,
    bulan2: null,

    search: null,
    lampiran: null,

    dialog1: false,
    modal1: false,

    dialog2: false,
    modal2: false,

    add: false,
    edit: false,

    formdraft: {
      show: false,
    },

    formteruskan: {
      show: false,
    },

    pejabats: [],
  }),
  computed: {
    ...mapState([
      "page",
      "theme",
      "http",
      "device",
      "record",
      "records",
      "loading",
      "event",
      "snackbar",
      "colors",
    ]),
    filterItems() {
      if (this.search != null) {
        return this.records.filter((item) => {
          return (
            item.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
          );
        });
      } else {
        return this.records;
      }
    },
  },
  created() {
    this.setPage({
      crud: true,
      dataUrl: "api/administrator/rekapitulasi/" + this.bulan,
      pagination: false,
      title: "Rekapitulasi Dokumen Per OPD/Dinas",
      subtitle:
        "Berikut adalah seluruh rekapitulasi dokumen per opd/dinas  yang tersedia",
      breadcrumbs: [
        {
          text: "E-Surat",
          disabled: true,
          href: "",
        },
        {
          text: "Rekapitulasi",
          disabled: true,
          href: "",
        },
      ],
      add: false,
      edit: false,
      actions: {
        refresh: false,
        add: false,
        edit: true,
        delete: true,
        bulkdelete: false,
        print: false,
        export: false,
        help: false,
      },
    });
    this.fetchRecords();
  },
  mounted() {},
  methods: {
    ...mapActions([
      "setPage",
      "fetchRecords",
      "postAddNew",
      "postEdit",
      "postUpdate",
      "postConfirmDelete",
      "assignFileBrowse",
      "setRecord",
      "setLoading",
    ]),
    fetchSearch: function () {
      this.setPage({
        crud: true,
        dataUrl: "api/administrator/rekapitulasi/" + this.bulan,
      });
      this.fetchRecords();
    },
    printReport: async function () {
      let { data } = await this.http.get(
        "api/administrator/rekapitulasi-pdf/" + this.bulan
      );

      this.data = data;

      var doc = new jsPDF("l", "mm", [297, 210]);
      doc.fromHTML(data);
    },
    downloadExcel: async function () {
      try {
        this.setLoading({
          dialog: true,
          text: "Proses Download Data",
        });
        let { data } = await this.http.post(
          "api/administrator/rekapitulasi-excel/" + this.bulan
        );

        var dataxls = XLSX.utils.json_to_sheet(data);

        // A workbook is the name given to an Excel file
        var wb = XLSX.utils.book_new(); // make Workbook of Excel

        // add Worksheet to Workbook
        // Workbook contains one or more worksheets
        XLSX.utils.book_append_sheet(wb, dataxls, "Daftar Rekapitulasi"); // sheetAName is name of Worksheet
        //XLSX.utils.book_append_sheet(wb, pokemonWS, "pokemons");

        // export Excel file
        XLSX.writeFile(wb, "daftar-rekapitulasi.xlsx"); // name of the file is 'book.xlsx'
      } catch (error) {
      } finally {
        this.setLoading({
          dialog: false,
          text: "",
        });
      }
    },
  },
  watch: {
    bulan: function () {
      this.fetchSearch();
    },
  },
};
</script>
