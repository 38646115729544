<template>
  <v-container
    fluid
    class="pt-10 grid-list-xl"
  >

    <v-row>
      <div class="ml-5 mb-10 font-weight-bold black--text">
        Selamat Datang, {{ user.name.toUpperCase() }}
        <div class="font-weight-regular grey--text">
          Inilah dashboard aplikasi anda
        </div>
      </div>
    </v-row>

    <v-row class="justify-center mb-10 pl-5 pr-5">
      <v-col
        cols="12"
        class=""
      >
      </v-col>
      <v-col :cols="device.desktop ? `4` : `12`">
        <v-card class="mx-auto">
          <v-toolbar
            color="purple "
            dark
          >
            <v-toolbar-title>Media Penyimpanan</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card
            class="mx-auto overflow-y-auto"
            height="350"
          >
            <v-col cols="12">
              <pei-storage-info />
            </v-col>
          </v-card>
        </v-card>
      </v-col>
      <v-col :cols="device.desktop ? `4` : `12`">
        <v-card class="mx-auto">
          <v-toolbar
            color="purple"
            dark
          >
            <v-toolbar-title>Pengguna</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card
            class="mx-auto overflow-y-auto"
            height="350"
          >
            <v-col cols="12">
              <pei-user />
            </v-col>
          </v-card>
        </v-card>
      </v-col>
      <v-col :cols="device.desktop ? `4` : `12`">
        <v-card class="mx-auto">
          <v-toolbar
            color="purple"
            dark
          >
            <v-toolbar-title>Dokumen</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card
            class="mx-auto overflow-y-auto"
            height="350"
          >
            <v-col cols="12">
              <pei-dokumen />
            </v-col>
          </v-card>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="justify-center mb-10 pl-5 pr-5">
      <v-col :cols="device.desktop ? 4:12">
        <v-card
          :class="`mx-auto elevation-10 dashboard-box-statistik `"
          outlined
        >
          <v-list-item three-line>
            <v-list-item-content>
              <div class="overline mb-4 white--text">SURAT MASUK</div>
              <v-list-item-title class="headline mb-1 white--text">
                {{ jmlsuratmasuk }}
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-avatar
              size="50"
              class="elevation-2"
              color="white + ` darken-1`"
            >
              <v-icon
                :color="theme.color"
                large
              >mdi-inbox-arrow-down</v-icon>
            </v-list-item-avatar>
          </v-list-item>

          <v-card-actions>
            <v-btn
              outlined
              rounded
              text
              class="white--text"
              @click="
                        $router.push({
                          name: 'administrator-surat-masuk-index',
                        })
                      "
            >
              Selengkapnya
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col :cols="device.desktop ?4:12">
        <v-card
          :class="`mx-auto elevation-10 dashboard-box-statistik` "
          outlined
        >
          <v-list-item three-line>
            <v-list-item-content>
              <div class="overline mb-4 white--text">SURAT KELUAR</div>
              <v-list-item-title class="headline mb-1 white--text">
                {{ jmlsuratkeluar }}
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-avatar
              size="50"
              class="elevation-2"
              color="white"
            >
              <v-icon
                large
                :color="theme.color"
              >mdi-inbox-arrow-up</v-icon>
            </v-list-item-avatar>
          </v-list-item>

          <v-card-actions>
            <v-btn
              outlined
              rounded
              text
              class="white--text"
              @click="
                        $router.push({ name: 'operator-surat-keluar-index' })
                      "
            >
              Selengkapnya
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col :cols="device.desktop ?4:12">
        <v-card
          :class="`mx-auto elevation-10 dashboard-box-statistik` "
          outlined
          dark
        >
          <v-list-item three-line>
            <v-list-item-content>
              <div class="overline mb-4 white--text">
                JML TOTAL DOKUMEN
              </div>
              <v-list-item-title class="headline mb-1 white--text">
                {{ jmltotal }}
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-avatar
              size="50"
              class="elevation-2"
              color="white"
            >
              <v-icon
                :color="theme.color"
                large
              >mdi-email-receive</v-icon>
            </v-list-item-avatar>
          </v-list-item>

          <v-card-actions>
            <v-btn
              outlined
              rounded
              text
              dark
              @click="$router.push({ name: 'user-draft-masuk' })"
            >
              Selengkapnya
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

    </v-row>
    <v-row class="justify-center mb-10 pl-5 pr-5">
      <v-col cols=12>
        <v-card class="">
          <v-toolbar
            color="purple"
            dark
          >

            <v-toolbar-title>Statistik data surat per OPD</v-toolbar-title>

            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="records"
              :items-per-page="10"
              class="elevation-2"
              :color="theme.color"
              :loading="loading.table"
              loading-text="Loading... Please wait"
              :search="search"
            >
              <v-progress-linear
                slot="progress"
                :color="theme.color"
                height="1"
                indeterminate
              ></v-progress-linear>
              <template v-slot:item.id="{ value }">
                <v-tooltip
                  color="orange"
                  bottom
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      text
                      small
                      icon
                      v-on="on"
                    >
                      <v-icon
                        color="orange"
                        @click="editRecord(value)"
                      >create</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit Data</span>
                </v-tooltip>
                <v-tooltip
                  color="red"
                  bottom
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      text
                      small
                      icon
                      v-on="on"
                    >
                      <v-icon
                        color="red"
                        @click="postConfirmDelete(value)"
                      >delete</v-icon>
                    </v-btn>
                  </template>

                  <span>Hapus Data</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";

import PeiStorageInfo from "./chart/PeiStorageInfo.vue";
import PeiUser from "./chart/PieUser.vue";
import PeiDokumen from "./chart/PieDokumen.vue";

export default {
  name: "operator-dashboard",

  components: {
    PeiStorageInfo,
    PeiUser,
    PeiDokumen,
  },

  data: () => ({
    num: 1,
    datacollection: null,
    chartData: {
      Books: 24,
      Magazine: 30,
      Newspapers: 10,
    },

    jmlsuratmasuk: 0,
    jmlsuratkeluar: 0,
    jmltotal: 0,

    jmlformasi: 0,
    jmlpelamar: 0,
    pelamars: [],
    pesans: [],

    search: null,
    jmlpegawai: 25,

    selected: [2],

    headers: [
      { text: "#", value: "num" },
      { text: "OPD", value: "nunker" },
      { text: "JML.SURAT MASUK", value: "jmlsuratmasuk", align: "right" },
      { text: "JML.SURAT KELUAR", value: "jmlsuratkeluar", align: "right" },
      { text: "TOTAL", value: "jmltotal", align: "right" },
    ],

    headers2: [
      { text: "#", value: "num" },
      {
        text: "PROGRAM KEAHLIAN",
        align: "start",
        sortable: false,
        value: "jurusan",
      },
      { text: "BELUM TERVERIFIKASI", value: "jmlbelum" },
      { text: "SUDAH TERVERIFIKASI", value: "jmlsudah" },
    ],

    headers3: [
      { text: "#", value: "num" },
      {
        text: "PROGRAM KEAHLIAN",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "PILIHAN I", value: "jmlpil1" },
      { text: "PILIHAN II", value: "jmlpil2" },
    ],

    records: [],

    records2: [],

    records3: [],
  }),
  computed: {
    ...mapState(["theme", "http", "device", "loading", "event", "user"]),
  },
  created() {
    this.setPage({
      crud: true,
      dataUrl: "api/dashboard",
      pagination: false,
      title: "Dashboard",
      subtitle: "Gambaran keseluruhan data aplikasi yang anda miliki",
      breadcrumbs: [
        {
          text: "Dashboard",
          disabled: false,
          href: "",
        },
      ],
      add: false,
      edit: false,
      actions: {
        refresh: false,
        add: false,
        edit: false,
        delete: false,
        bulkdelete: false,
        print: false,
        export: false,
        help: false,
      },
    });
    this.fetchDashboard();
    this.fetchSummaryOpd();
  },
  mounted() {},
  methods: {
    ...mapActions([
      "setPage",
      "fetchRecords",
      "postAddNew",
      "postEdit",
      "postUpdate",
      "postConfirmDelete",
    ]),

    fetchDashboard: async function () {
      let {
        data: { jmlsuratmasuk, jmlsuratkeluar, jmltotal },
      } = await this.http.get("api/dashboard-index");

      this.jmlsuratmasuk = jmlsuratmasuk;
      this.jmlsuratkeluar = jmlsuratkeluar;
      this.jmltotal = jmltotal;
    },

    fetchSummaryOpd: async function () {
      let { data } = await this.http.get("api/dashboard-per-opd");

      this.records = data;
    },

    fetchPesan: async function () {
      let {
        data: { pesans },
      } = await this.http.get("api/dashboard-pesa");

      this.pesans = pesans;
    },

    fetchPerHari: async function () {
      let {
        data: { headers, records },
      } = await this.http.get("api/dashboard-perhari");

      this.headers = headers;
      this.records = records;
    },

    fethDataVerifikasi: async function () {
      let {
        data: { data },
      } = await this.http.get("api/dashboard-verifikasi");

      this.records2 = data;
    },

    fethDataPerjurusanPerPilihan: async function () {
      let {
        data: { datas },
      } = await this.http.get("api/dashboard-perjurusan-per-pilihan");

      this.records3 = datas;
    },
  },
};
</script>

<style>
.small {
  max-width: 600px;
  margin: 150px auto;
}
</style>
