<template>
  <div :class="device.desktop ? `home pa-6 grey lighten-4`:`home pa-0 grey lighten-4`">
    <v-row>
      <v-col col="12">
        <v-card class="animated fadeInUp">
          <v-card-title class="flex flex-row-reverse purple lighten-1">
            <v-tooltip
              color="white"
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  small
                  icon
                  v-on="on"
                >
                  <v-icon
                    color="white"
                    @click="
                      $router.push({ name: 'operator-surat-keluar-index' })
                    "
                  >cancel</v-icon>
                </v-btn>
              </template>
              <span>Tutup</span>
            </v-tooltip>
            <v-tooltip
              color="green"
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  small
                  icon
                  v-on="on"
                >
                  <v-icon
                    color="white"
                    @click="fetchRecords"
                  >refresh</v-icon>
                </v-btn>
              </template>
              <span>Refresh Data</span>
            </v-tooltip>
            <v-tooltip
              :color="theme.color"
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  small
                  icon
                  v-on="on"
                >
                  <v-icon
                    color="white"
                    @click="openAddNew"
                  >add_circle</v-icon>
                </v-btn>
              </template>
              <span>Tambah Surat Keluar</span>
            </v-tooltip>

            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pencarian"
              single-line
              hide-details
              solo
              :color="theme.color"
              dense
            ></v-text-field>
          </v-card-title>
          <v-data-table
            v-show="device.desktop"
            :headers="headers"
            :items="records"
            :items-per-page="10"
            class="elevation-2"
            :color="theme.color"
            :loading="loading.table"
            loading-text="Loading... Please wait"
            :search="search"
            show-select
          >
            <v-progress-linear
              slot="progress"
              :color="theme.color"
              height="1"
              indeterminate
            ></v-progress-linear>

            <template v-slot:item.sifat_surat="{ value }">
              <v-icon :color="value.color">{{ value.icon }}</v-icon>
            </template>

            <template v-slot:item.status="{ value }">
              <v-chip
                small
                :color="value.color"
              >{{ value.text }}</v-chip>
            </template>

            <template v-slot:item.id="{ value }">
              <v-tooltip
                color="red"
                bottom
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    text
                    small
                    icon
                    v-on="on"
                  >
                    <v-icon
                      color="red"
                      @click="postDeleteRecord(value)"
                    >mdi-delete</v-icon>
                  </v-btn>
                </template>

                <span>Hapus</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-col col="12">
      <v-dialog
        transition="dialog-bottom-transition"
        v-model="add"
        :max-width="device.desktop ? `600px` : `100%`"
        persistent
      >
        <v-card>
          <v-toolbar
            :color="theme.color"
            dark
          >Formulir Pengiriman Draft Surat</v-toolbar>

          <v-card-text>
            <v-col cols="12">
              <v-select
                label="Pejabat"
                :color="theme.color"
                v-model="record.pejabat_id"
                outlined
                dense
                hide-details
                :items="pejabats"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-textarea
                label="Pesan/Catatan"
                :color="theme.color"
                v-model="record.note"
                outlined
                dense
                rows="5"
                hide-details
              ></v-textarea>
            </v-col>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-end">
            <v-btn
              outlined
              color="grey"
              @click="add = false"
            >Batal</v-btn>
            <v-btn
              outlined
              :color="theme.color"
              @click="postAddNewRecord"
            >Kirim</v-btn>
            <v-btn
              outlined
              :color="theme.color"
              v-show="edit"
              @click="postAddNewRecord"
            >Ubah</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "Home",
  data: () => ({
    num: 1,
    headers: [
      { text: "NIP", value: "nip" },
      { text: "Nama", value: "name" },
      { text: "Catatan", value: "catatan", align: "center" },
      { text: "Status", value: "status", align: "center" },
      {
        text: "Aksi",
        value: "id",
        align: "center",
        width: 85,
        sortable: false,
      },
    ],

    search: null,
    lampiran: null,

    add: false,
    edit: false,

    formdraft: {
      show: false,
    },

    pejabats: [],
  }),
  computed: {
    ...mapState([
      "page",
      "theme",
      "http",
      "device",
      "record",
      "records",
      "loading",
      "event",
    ]),
    filterItems() {
      if (this.search != null) {
        return this.records.filter((item) => {
          return (
            item.perihal.toLowerCase().indexOf(this.search.toLowerCase()) > -1
          );
        });
      } else {
        return this.records;
      }
    },
  },
  created() {
    this.setPage({
      crud: true,
      dataUrl: "api/operator/approval/" + this.$route.params.id,
      title: "PERSETUJUAN DRAFT SURAT KELUAR",
      subtitle:
        "Berikut adalah seluruh persutujuan draft surat keluar yang tersedia",
      breadcrumbs: [
        {
          text: "Surat Keluar",
          disabled: false,
          href: "",
        },
        {
          text: "Persetujuan Draft",
          disabled: true,
          href: "",
        },
      ],
      add: false,
      edit: false,
      actions: {
        refresh: true,
        add: true,
        edit: true,
        delete: true,
        bulkdelete: false,
        print: false,
        export: false,
        help: false,
      },
    });
    this.fetchRecords();
    this.fetchPejabat();
  },
  mounted() {},
  methods: {
    ...mapActions([
      "setPage",
      "fetchRecords",
      "postAddNew",
      "postEdit",
      "postUpdate",
      "postConfirmDelete",
      "assignFileBrowse",
      "setRecord",
    ]),
    openAddNew: function () {
      this.add = true;
    },
    postAddNewRecord: function () {
      this.postAddNew(this.record).then(() => {
        this.fetchRecords();
        this.add = false;
      });
    },
    editRecord: function (val) {
      this.$router.push({
        name: "operator-verifikasi-ak-satu-create",
        params: { id: val },
      });
    },

    postUpdateRecord: function () {
      this.postUpdate(this.record).then(() => {
        this.fetchRecords();
        this.path = "";
        this.add = false;
        this.edit = false;
      });
    },

    postDeleteRecord: function (val) {
      this.postConfirmDelete(val);
    },

    fetchPejabat: async function () {
      let { data: data } = await this.http.post(
        "api/master-data/combo-pejabat"
      );
      this.pejabats = data;
    },

    uploadFile: function () {
      this.assignFileBrowse({
        fileType: [".jpeg", ".jpg", ".png"],
        query: {
          doctype: "storage",
        },
        callback: (response) => {
          setTimeout(() => {
            this.lampiran = response.name;
            this.record.lampiran = response.name;
          }, 500);
        },
      });
    },

    postDownload(val) {
      window.open(val, "__blank");
    },
  },
};
</script>
