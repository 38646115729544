var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.device.desktop ? "home pa-6 grey lighten-4":"home pa-0 grey lighten-4"},[_c('v-row',[_c('v-col',{attrs:{"col":"12"}},[_c('v-card',{staticClass:"animated animate__bounceIn"},[_c('v-card-title',{class:"flex flex-row-reverse " + _vm.colors.titlebar},[_c('v-tooltip',{attrs:{"color":_vm.theme.color,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","small":"","icon":""}},on),[_c('v-icon',{attrs:{"color":"white"},on:{"click":_vm.downloadExcel}},[_vm._v("mdi-file-excel")])],1)]}}])},[_c('span',[_vm._v("Export Data")])]),_c('v-tooltip',{attrs:{"color":_vm.theme.color,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","small":"","icon":""}},on),[_c('v-icon',{attrs:{"color":"white"},on:{"click":_vm.printReport}},[_vm._v("mdi-printer")])],1)]}}])},[_c('span',[_vm._v("Cetak Data")])]),_c('v-tooltip',{attrs:{"color":_vm.theme.color,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","small":"","icon":""}},on),[_c('v-icon',{attrs:{"color":"white"},on:{"click":_vm.fetchSearch}},[_vm._v("refresh")])],1)]}}])},[_c('span',[_vm._v("Refresh Data")])]),_c('v-spacer'),_c('v-col',{attrs:{"cols":_vm.device.desktop ? "3" : "12"}},[_c('v-dialog',{ref:"dialog2",attrs:{"return-value":_vm.record.bulan2,"persistent":"","width":"290px","dense":"","hide-details":"","color":_vm.colors.secondary},on:{"update:returnValue":function($event){return _vm.$set(_vm.record, "bulan2", $event)},"update:return-value":function($event){return _vm.$set(_vm.record, "bulan2", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"S.d Bulan","prepend-icon":"mdi-calendar","readonly":"","solo":"","color":_vm.colors.secondary,"dense":"","hide-details":""},model:{value:(_vm.bulan2),callback:function ($$v) {_vm.bulan2=$$v},expression:"bulan2"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modal2),callback:function ($$v) {_vm.modal2=$$v},expression:"modal2"}},[_c('v-date-picker',{attrs:{"color":_vm.colors.secondary,"type":"month","scrollable":""},model:{value:(_vm.bulan2),callback:function ($$v) {_vm.bulan2=$$v},expression:"bulan2"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"grey"},on:{"click":function($event){_vm.modal2 = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":_vm.colors.secondary},on:{"click":function($event){return _vm.$refs.dialog2.save(_vm.bulan2)}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{attrs:{"cols":_vm.device.desktop ? "3" : "12"}},[_c('v-dialog',{ref:"dialog1",attrs:{"return-value":_vm.record.bulan1,"persistent":"","width":"290px","dense":"","hide-details":"","color":_vm.colors.secondary},on:{"update:returnValue":function($event){return _vm.$set(_vm.record, "bulan1", $event)},"update:return-value":function($event){return _vm.$set(_vm.record, "bulan1", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Dari Bulan","prepend-icon":"mdi-calendar","readonly":"","solo":"","color":"white","dense":"","hide-details":""},model:{value:(_vm.bulan1),callback:function ($$v) {_vm.bulan1=$$v},expression:"bulan1"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modal1),callback:function ($$v) {_vm.modal1=$$v},expression:"modal1"}},[_c('v-date-picker',{attrs:{"color":_vm.colors.secondary,"type":"month","scrollable":""},model:{value:(_vm.bulan1),callback:function ($$v) {_vm.bulan1=$$v},expression:"bulan1"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"grey"},on:{"click":function($event){_vm.modal1 = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":_vm.theme.color},on:{"click":function($event){return _vm.$refs.dialog1.save(_vm.bulan1)}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Pencarian","single-line":"","hide-details":"","dense":"","solo":"","color":_vm.colors.secondary},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(_vm.device.desktop),expression:"device.desktop"}],staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.records,"items-per-page":10,"color":_vm.theme.color,"loading":_vm.loading.table,"loading-text":"Loading... Please wait","search":_vm.search}},[_c('v-progress-linear',{attrs:{"slot":"progress","color":_vm.theme.color,"height":"1","indeterminate":""},slot:"progress"})],1),_c('v-list',{directives:[{name:"show",rawName:"v-show",value:(_vm.device.mobile),expression:"device.mobile"}],attrs:{"subheader":"","three-line":""}},[_c('v-list-item-group',{attrs:{"multiple":"","active-class":""}},_vm._l((_vm.filterItems),function(item){return _c('v-list-item',{key:item.id,staticStyle:{"border":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"color":_vm.theme.color,"input-value":active}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.nunker))]),_c('v-list-item-subtitle',[_vm._v("Surat Masuk : "+_vm._s(item.jmlsk))]),_c('v-list-item-subtitle',[_vm._v("Surat Keluar : "+_vm._s(item.jmlsm))]),_c('v-list-item-subtitle',[_vm._v("Total : "+_vm._s(item.total))])],1)]}}],null,true)})}),1)],1)],1)],1)],1),_c('v-col',{attrs:{"col":"12"}},[_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":_vm.device.desktop ? "500px" : "100%","persistent":""},model:{value:(_vm.formteruskan.show),callback:function ($$v) {_vm.$set(_vm.formteruskan, "show", $$v)},expression:"formteruskan.show"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":_vm.theme.color,"dark":""}},[_vm._v("Formulir Teruskan Surat")]),_c('v-card-text',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"label":"Pejabat | Personal","color":_vm.theme.color,"outlined":"","dense":"","hide-details":"","items":_vm.pejabats,"multiple":""},model:{value:(_vm.record.pejabats),callback:function ($$v) {_vm.$set(_vm.record, "pejabats", $$v)},expression:"record.pejabats"}})],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"outlined":"","color":_vm.theme.color}},[_vm._v("Kirim")]),_c('v-btn',{attrs:{"outlined":"","color":"grey"},on:{"click":function($event){_vm.formteruskan.show = false}}},[_vm._v("Batal")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }