<template>
  <div class="home pa-6">
    <v-flex row class="pb-2">
      <v-icon :color="theme.color">mdi-email-send</v-icon>
      <v-subheader class="text-h6">
        <h4>DRAFT KELUAR</h4>
      </v-subheader>
    </v-flex>
    <v-flex class="pb-5 pl-7">
      <div class="grey--text mb-2">
        Daftar Seluruh Data Draft Surat Keluar Yang Dimiliki Anda
      </div>
    </v-flex>

    <v-row>
      <v-col col="12">
        <v-card>
          <v-card-title class="flex flex-row-reverse purple lighten-1">
            <v-tooltip color="green" bottom>
              <template v-slot:activator="{ on }">
                <v-btn text small icon v-on="on">
                  <v-icon color="white" @click="fetchRecords">refresh</v-icon>
                </v-btn>
              </template>
              <span>Refresh Data</span>
            </v-tooltip>

            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pencarian"
              single-line
              hide-details
              solo
              :color="theme"
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="records"
            :items-per-page="10"
            class="elevation-2"
            :color="theme"
            :loading="loading.table"
            loading-text="Loading... Please wait"
            :search="search"
          >
            <v-progress-linear
              slot="progress"
              :color="theme"
              height="1"
              indeterminate
            ></v-progress-linear>

            <template v-slot:item.status="{ value }">
              <v-chip small :color="value.color">{{ value.text }}</v-chip>
            </template>

            <template v-slot:item.pdf="{ value }">
              <v-icon color="green" @click="postDownload(value)"
                >cloud_download</v-icon
              >
            </template>

            <template v-slot:item.id="{ value }">
              <v-tooltip color="red" bottom>
                <template v-slot:activator="{ on }">
                  <v-btn text small icon v-on="on">
                    <v-icon color="red" @click="postDeleteRecord(value)"
                      >mdi-delete</v-icon
                    >
                  </v-btn>
                </template>

                <span>Hapus</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-col col="12">
      <v-dialog
        transition="dialog-bottom-transition"
        v-model="formteruskan.show"
        :max-width="device.desktop ? `600px` : `100%`"
        persistent
      >
        <v-card>
          <v-toolbar :color="theme.color" dark
            >Formulir Teruskan Surat</v-toolbar
          >

          <v-card-text>
            <v-col cols="12">
              <v-autocomplete
                label="Kepada"
                :color="theme.color"
                outlined
                dense
                hide-details
                :items="pejabats"
                multiple
                v-model="record.pejabats"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-textarea
                label="Pesan"
                :color="theme.color"
                outlined
                dense
                hide-details
                v-model="record.note"
                rows="3"
              ></v-textarea>
            </v-col>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-end">
            <v-btn outlined :color="theme.color" @click="postAddNewRecord"
              >Kirim</v-btn
            >
            <v-btn
              outlined
              :color="theme.color"
              v-show="edit"
              @click="postUpdateRecord"
              >Ubah</v-btn
            >
            <v-btn outlined color="grey" @click="formteruskan.show = false"
              >Batal</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
    <v-col col="12">
      <v-dialog
        transition="dialog-bottom-transition"
        v-model="formesign.show"
        :max-width="device.desktop ? `400px` : `100%`"
        persistent
      >
        <v-card>
          <v-toolbar :color="theme.color" dark>Formulir e-Signature</v-toolbar>

          <v-card-text>
            <v-col cols="12">
              <div class="text-center efont-weight-bold grey--text">
                SILAHKAN MASUKAN PASSPHRASE ANDA:
              </div>
            </v-col>

            <v-col cols="12">
              <v-text-field
                label="Passphrase"
                :color="theme.color"
                v-model="record.passphrase"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-end">
            <v-btn outlined :color="theme.color" @click="postEsign">OK</v-btn>

            <v-btn outlined color="grey" @click="formesign.show = false"
              >Batal</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
    <v-col col="12">
      <v-dialog
        transition="dialog-bottom-transition"
        v-model="formtolak.show"
        :max-width="device.desktop ? `600px` : `100%`"
        persistent
      >
        <v-card>
          <v-toolbar :color="theme.color" dark
            >Formulir Tolak/Perbaiki Draft Surat</v-toolbar
          >

          <v-card-text>
            <v-col cols="12">
              <v-textarea
                label="Pesan Alasan/Perbaikan"
                :color="theme.color"
                outlined
                dense
                hide-details
                v-model="record.jawaban"
                rows="3"
              ></v-textarea>
            </v-col>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-end">
            <v-btn outlined :color="theme.color" @click="postTolak"
              >Kirim</v-btn
            >

            <v-btn outlined color="grey" @click="formtolak.show = false"
              >Batal</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "Home",
  data: () => ({
    num: 1,
    headers: [
      { text: "#", value: "num", align: "right" },
      { text: "Nomor", value: "nomor_surat" },
      { text: "Tgl. Surat", value: "tanggal_surat" },
      { text: "Kepada", value: "kepada", align: "left" },
      { text: "Catatan", value: "jawaban" },
      { text: "Dok", value: "pdf", align: "center" },
      { text: "Status", value: "status", align: "center" },
      { text: "OPSI", value: "id", align: "center" },
    ],

    search: null,
    lampiran: null,

    add: false,
    edit: false,

    formteruskan: {
      show: false,
    },
    formesign: {
      show: false,
    },
    formtolak: {
      show: false,
    },

    pejabats: [],
  }),
  computed: {
    ...mapState([
      "theme",
      "http",
      "device",
      "record",
      "records",
      "loading",
      "event",
      "snackbar",
    ]),
  },
  created() {
    this.setPage({
      crud: true,
      dataUrl: "api/user/draft-keluar",
    });
    this.fetchRecords();
    this.fetchPejabat();
  },
  mounted() {},
  methods: {
    ...mapActions([
      "setPage",
      "fetchRecords",
      "postAddNew",
      "postEdit",
      "postUpdate",
      "postConfirmDelete",
      "assignFileBrowse",
      "setRecord",
      "setLoading",
    ]),

    postDeleteRecord: function (val) {
      this.postConfirmDelete(val);
    },

    postDownload(val) {
      window.open(val, "__blank");
    },
  },
};
</script>
