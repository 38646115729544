<template>
  <div :class="device.desktop ? `home pa-6 grey lighten-4`:`home pa-0 grey lighten-4`">
    <v-row class="pa-1">
      <v-col cols="12">
        <v-card class="animated fadeInUp">
          <v-card-title :class="theme.color + ` lighten-1 white--text`">Formulir Ubah Surat Masuk</v-card-title>

          <v-divider></v-divider>
          <v-card-text>
            <v-col cols="12">
              <v-col cols="12">
                <v-select
                  label="Group Jenis Surat"
                  :color="theme.color"
                  dense
                  outlined
                  hide-details
                  v-model="record.group_jenis_surat_id"
                  :items="groupjenissurats"
                  @change="fetchJenisSurat"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  label="Jenis Surat|Klasifikasi Surat"
                  :color="theme.color"
                  dense
                  outlined
                  hide-details
                  v-model="record.jenis_surat_id"
                  :items="jenissurats"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-row>
                  <v-col :cols="device.desktop ? '6' : '12'">
                    <v-select
                      label="Sifat Surat"
                      :color="theme.color"
                      dense
                      outlined
                      hide-details
                      v-model="record.sifat_surat_id"
                      :items="sifatsurats"
                    ></v-select>
                  </v-col>
                  <v-col :cols="device.desktop ? '6' : '12'">
                    <v-select
                      label="Tipe Surat"
                      :color="theme.color"
                      dense
                      outlined
                      hide-details
                      v-model="record.type"
                      :items="types"
                    ></v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-row>
                      <v-col :cols="device.desktop ? '6' : '12'">
                        <v-text-field
                          label="Nomor Surat"
                          :color="theme.color"
                          v-model="record.nomor_surat"
                          hide-details
                          dense
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col :cols="device.desktop ? '6' : '12'">
                        <v-text-field
                          label="Tanggal Surat"
                          :color="theme.color"
                          v-model="record.tanggal_surat"
                          hide-details
                          dense
                          outlined
                          type="date"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              <v-col>
                <v-textarea
                  label="Perihal"
                  :color="theme.color"
                  outlined
                  dense
                  hide-details
                  v-model="record.perihal"
                  rows="3"
                >{{ record.perihal }}</v-textarea>
              </v-col>
              <v-col>
                <v-textarea
                  label="Ringkasan/Ulasan"
                  :color="theme.color"
                  outlined
                  dense
                  hide-details
                  v-model="record.ringkasan"
                  rows="3"
                >{{ record.ringkasan }}</v-textarea>
              </v-col>
              <v-col>
                <v-textarea
                  label="Tujuan Instansi/Umum"
                  :color="theme.color"
                  outlined
                  dense
                  hide-details
                  v-model="record.umum"
                  rows="3"
                ></v-textarea>
              </v-col>
              <v-col
                cols="12"
                v-show="false"
              >
                <v-select
                  label="Ditandatangani oleh "
                  :color="theme.color"
                  dense
                  outlined
                  hide-details
                  v-model="record.pejabat_id"
                  :items="pejabats"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-row>
                  <v-col :cols="device.desktop ? `3` : `12`">
                    <iframe
                      :src="pdf"
                      @click="uploadSurat"
                      frameborder="0"
                      style="background-color: grey"
                      :width="device.desktop ? 240 : `100%`"
                      height="300"
                    >
                    </iframe>
                    <v-row class="text--left">
                      <v-col class="text-left">
                        <v-btn
                          :color="theme.color"
                          block
                          outlined
                          @click="uploadSurat"
                        >
                          <v-icon>cloud_upload</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <center>Dokumen Surat (PDF) Max: 2Mb</center>
                  </v-col>
                </v-row>
              </v-col>
            </v-col>
          </v-card-text>
          <v-card-actions class="mt-5">
            <v-col class="text-right">
              <v-btn
                outlined
                color="grey"
                @click="$router.push({ name: 'operator-surat-masuk-index' })"
              >BATAL</v-btn>
              &nbsp;
              <v-btn
                outlined
                :color="theme.color"
                @click="postUpdate"
              >SIMPAN</v-btn>
            </v-col>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "perusahaan-index",
  data: () => ({
    foto: "/",
    record: {},
    jenisperusahaans: [],
    bidangusahas: [],
    negaras: [],
    provinsis: [],
    kabupatens: [],
    kecamatans: [],
    desas: [],

    //surat
    groupjenissurats: [],
    jenissurats: [],
    sifatsurats: [],
    types: [
      { text: "Internal", value: "internal" },
      { text: "External/Umum", value: "external" },
    ],
    pejabats: [],
    pdf: null,
  }),
  computed: {
    ...mapState([
      "theme",
      "http",
      "device",
      "records",
      "loading",
      "event",
      "snackbar",
    ]),
  },
  created() {
    this.setPage({
      crud: true,
      dataUrl: "api/",
      pagination: false,
      title: "UBAH SURAT MASUK ",
      subtitle: "Formulir Ubah Surat Masuk",
      breadcrumbs: [
        {
          text: "Surat Masuk",
          disabled: false,
          href: "",
        },
        {
          text: "Ubah",
          disabled: true,
          href: "",
        },
      ],
      add: false,
      edit: false,
      actions: {
        refresh: true,
        add: true,
        edit: true,
        delete: true,
        bulkdelete: false,
        print: false,
        export: false,
        help: false,
      },
    });
    this.fetchRecord();
    this.fetchGroupJenisSurat();
    this.fetchSifatSurat();
    this.fetchPejabats();
    this.fetchNegara();
  },
  mounted() {},
  methods: {
    ...mapActions(["setPage", "assignFileBrowse", "setLoading"]),

    fetchRecord: async function () {
      try {
        let { data } = await this.http.get(
          "api/operator/surat-masuk/" + this.$route.params.id
        );
        this.record = data;
        this.pdf = data.pdf_path;
      } catch (error) {}
    },

    postUpdate: async function () {
      try {
        this.setLoading({ dialog: true, text: "Proses Simpan Data" });
        let {
          data: { status, message },
        } = await this.http.post(
          "api/operator/surat-masuk/" + this.record.id + "?_method=PUT",
          this.record
        );

        if (!status) {
          this.snackbar.color = "red";
          this.snackbar.text = message;
          this.snackbar.state = true;
          return;
        }

        this.snackbar.color = this.theme.color;
        this.snackbar.text = message;
        this.snackbar.state = true;
        this.$router.push({ name: "operator-surat-masuk-index" });
      } catch (error) {
      } finally {
        this.setLoading({ dialog: false, text: "" });
      }
    },

    fetchGroupJenisSurat: async function () {
      let { data: data } = await this.http.post(
        "api/master-data/combo-group-jenis-surat"
      );
      this.groupjenissurats = data;
    },

    fetchJenisSurat: async function () {
      let { data: data } = await this.http.post(
        "api/master-data/combo-jenis-surat",
        this.record
      );
      this.jenissurats = data;
    },

    fetchSifatSurat: async function () {
      let { data: data } = await this.http.post(
        "api/master-data/combo-sifat-surat"
      );
      this.sifatsurats = data;
    },

    fetchPejabats: async function () {
      let { data: data } = await this.http.post(
        "api/master-data/combo-pejabat"
      );
      this.pejabats = data;
    },

    fetchNegara: async function () {
      let { data: data } = await this.http.post("api/master-data/combo-negara");
      this.negaras = data;
    },

    uploadSurat: function () {
      this.assignFileBrowse({
        fileType: [".pdf"],
        query: {
          doctype: "documents",
        },
        callback: (response) => {
          setTimeout(() => {
            this.pdf = response.path;
            this.record.pdf = response.name;
          }, 1000);
        },
      });
    },
  },
  watch: {
    "record.group_jenis_surat_id": function () {
      this.fetchJenisSurat();
    },
  },
};
</script>

<style></style>
