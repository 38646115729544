var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.device.desktop ? "home pa-6 grey lighten-4":"home pa-0 grey lighten-4"},[_c('v-row',[_c('v-col',{attrs:{"col":"12"}},[_c('v-card',{staticClass:"animated fadeInUp"},[_c('v-card-title',{staticClass:"flex flex-row-reverse purple lighten-1"},[_c('v-tooltip',{attrs:{"color":"white","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","small":"","icon":""}},on),[_c('v-icon',{attrs:{"color":"white"},on:{"click":function($event){return _vm.$router.push({ name: 'operator-surat-keluar-index' })}}},[_vm._v("cancel")])],1)]}}])},[_c('span',[_vm._v("Tutup")])]),_c('v-tooltip',{attrs:{"color":"green","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","small":"","icon":""}},on),[_c('v-icon',{attrs:{"color":"white"},on:{"click":_vm.fetchRecords}},[_vm._v("refresh")])],1)]}}])},[_c('span',[_vm._v("Refresh Data")])]),_c('v-tooltip',{attrs:{"color":_vm.theme.color,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","small":"","icon":""}},on),[_c('v-icon',{attrs:{"color":"white"},on:{"click":_vm.openAddNew}},[_vm._v("add_circle")])],1)]}}])},[_c('span',[_vm._v("Tambah Surat Keluar")])]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Pencarian","single-line":"","hide-details":"","solo":"","color":_vm.theme.color,"dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(_vm.device.desktop),expression:"device.desktop"}],staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.records,"items-per-page":10,"color":_vm.theme.color,"loading":_vm.loading.table,"loading-text":"Loading... Please wait","search":_vm.search,"show-select":""},scopedSlots:_vm._u([{key:"item.sifat_surat",fn:function(ref){
var value = ref.value;
return [_c('v-icon',{attrs:{"color":value.color}},[_vm._v(_vm._s(value.icon))])]}},{key:"item.status",fn:function(ref){
var value = ref.value;
return [_c('v-chip',{attrs:{"small":"","color":value.color}},[_vm._v(_vm._s(value.text))])]}},{key:"item.id",fn:function(ref){
var value = ref.value;
return [_c('v-tooltip',{attrs:{"color":"red","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","small":"","icon":""}},on),[_c('v-icon',{attrs:{"color":"red"},on:{"click":function($event){return _vm.postDeleteRecord(value)}}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Hapus")])])]}}])},[_c('v-progress-linear',{attrs:{"slot":"progress","color":_vm.theme.color,"height":"1","indeterminate":""},slot:"progress"})],1)],1)],1)],1),_c('v-col',{attrs:{"col":"12"}},[_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":_vm.device.desktop ? "600px" : "100%","persistent":""},model:{value:(_vm.add),callback:function ($$v) {_vm.add=$$v},expression:"add"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":_vm.theme.color,"dark":""}},[_vm._v("Formulir Pengiriman Draft Surat")]),_c('v-card-text',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"label":"Pejabat","color":_vm.theme.color,"outlined":"","dense":"","hide-details":"","items":_vm.pejabats},model:{value:(_vm.record.pejabat_id),callback:function ($$v) {_vm.$set(_vm.record, "pejabat_id", $$v)},expression:"record.pejabat_id"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":"Pesan/Catatan","color":_vm.theme.color,"outlined":"","dense":"","rows":"5","hide-details":""},model:{value:(_vm.record.note),callback:function ($$v) {_vm.$set(_vm.record, "note", $$v)},expression:"record.note"}})],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"outlined":"","color":"grey"},on:{"click":function($event){_vm.add = false}}},[_vm._v("Batal")]),_c('v-btn',{attrs:{"outlined":"","color":_vm.theme.color},on:{"click":_vm.postAddNewRecord}},[_vm._v("Kirim")]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.edit),expression:"edit"}],attrs:{"outlined":"","color":_vm.theme.color},on:{"click":_vm.postAddNewRecord}},[_vm._v("Ubah")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }