<template>
  <div class="home pa-6">
    <v-flex row class="pb-2">
      <v-icon :color="theme.color">mdi-email-receive</v-icon>
      <v-subheader class="text-h6">
        <h4>DRAFT MASUK</h4>
      </v-subheader>
    </v-flex>
    <v-flex class="pb-5 pl-7">
      <div class="grey--text mb-2">
        Daftar Seluruh Data Draft Masuk Yang Dimiliki Anda
      </div>
    </v-flex>

    <v-row>
      <v-col col="12">
        <v-card>
          <v-card-title class="flex flex-row-reverse purple lighten-1">
            <v-tooltip color="green" bottom>
              <template v-slot:activator="{ on }">
                <v-btn text small icon v-on="on">
                  <v-icon color="white" @click="fetchRecords">refresh</v-icon>
                </v-btn>
              </template>
              <span>Refresh Data</span>
            </v-tooltip>

            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pencarian"
              single-line
              hide-details
              solo
              :color="theme"
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="records"
            :items-per-page="10"
            class="elevation-2"
            :color="theme"
            :loading="loading.table"
            loading-text="Loading... Please wait"
            :search="search"
            show-select
          >
            <v-progress-linear
              slot="progress"
              :color="theme"
              height="1"
              indeterminate
            ></v-progress-linear>

            <template v-slot:item.esign_status="{ value }">
              <v-icon :color="value.color">{{ value.icon }}</v-icon>
            </template>

            <template v-slot:item.pdf="{ value }">
              <v-icon color="green" @click="postDownload(value)"
                >cloud_download</v-icon
              >
            </template>

            <template v-slot:item.id="{ value }">
              <v-menu
                bottom
                origin="center center"
                transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon :color="theme.color" v-bind="attrs" v-on="on">
                    mdi-dots-vertical-circle-outline
                  </v-icon>
                </template>

                <v-list>
                  <v-list-item @click="openTeruskan(value)">
                    <v-list-item-title>
                      <v-icon color="blue">mdi-send</v-icon>
                      Teruskan
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="openEsign(value)">
                    <v-list-item-title>
                      <v-icon color="green">mdi-fingerprint</v-icon>
                      Tandatangani
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item @click="openTolak(value)">
                    <v-list-item-title>
                      <v-icon color="red">mdi-bell-cancel</v-icon>
                      Tolak/Perbaiki
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-col col="12">
      <v-dialog
        transition="dialog-bottom-transition"
        v-model="formteruskan.show"
        :max-width="device.desktop ? `600px` : `100%`"
        persistent
      >
        <v-card>
          <v-toolbar :color="theme.color" dark
            >Formulir Teruskan Surat</v-toolbar
          >

          <v-card-text>
            <v-col cols="12">
              <v-autocomplete
                label="Kepada"
                :color="theme.color"
                outlined
                dense
                hide-details
                :items="pejabats"
                multiple
                v-model="record.pejabats"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-textarea
                label="Pesan"
                :color="theme.color"
                outlined
                dense
                hide-details
                v-model="record.note"
                rows="3"
              ></v-textarea>
            </v-col>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-end">
            <v-btn outlined :color="theme.color" @click="postAddNewRecord"
              >Kirim</v-btn
            >
            <v-btn
              outlined
              :color="theme.color"
              v-show="edit"
              @click="postUpdateRecord"
              >Ubah</v-btn
            >
            <v-btn outlined color="grey" @click="formteruskan.show = false"
              >Batal</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
    <v-col col="12">
      <v-dialog
        transition="dialog-bottom-transition"
        v-model="formesign.show"
        :max-width="device.desktop ? `400px` : `100%`"
        persistent
      >
        <v-card>
          <v-toolbar :color="theme.color" dark>Formulir e-Signature</v-toolbar>

          <v-card-text>
            <v-col cols="12">
              <div class="text-center efont-weight-bold grey--text">
                SILAHKAN MASUKAN PASSPHRASE ANDA:
              </div>
            </v-col>

            <v-col cols="12">
              <v-text-field
                label="Passphrase"
                :color="theme.color"
                v-model="record.passphrase"
                outlined
                dense
                hide-details
                type="password"
              ></v-text-field>
            </v-col>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-end">
            <v-btn outlined :color="theme.color" @click="postEsign">OK</v-btn>

            <v-btn outlined color="grey" @click="formesign.show = false"
              >Batal</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
    <v-col col="12">
      <v-dialog
        transition="dialog-bottom-transition"
        v-model="formtolak.show"
        :max-width="device.desktop ? `600px` : `100%`"
        persistent
      >
        <v-card>
          <v-toolbar :color="theme.color" dark
            >Formulir Tolak/Perbaiki Draft Surat</v-toolbar
          >

          <v-card-text>
            <v-col cols="12">
              <v-textarea
                label="Pesan Alasan/Perbaikan"
                :color="theme.color"
                outlined
                dense
                hide-details
                v-model="record.jawaban"
                rows="3"
              ></v-textarea>
            </v-col>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-end">
            <v-btn outlined :color="theme.color" @click="postTolak"
              >Kirim</v-btn
            >

            <v-btn outlined color="grey" @click="formtolak.show = false"
              >Batal</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "Home",
  data: () => ({
    num: 1,
    headers: [
      { text: "#", value: "num", align: "right" },
      { text: "Nomor", value: "nomor_surat" },
      { text: "Tgl. Surat", value: "tanggal_surat" },
      { text: "Perihal", value: "perihal" },
      { text: "Dari", value: "dari", align: "left" },
      { text: "Dok", value: "pdf", align: "center" },
      { text: "Status", value: "esign_status", align: "center" },
      { text: "OPSI", value: "id", align: "center" },
    ],

    search: null,
    lampiran: null,

    add: false,
    edit: false,

    formteruskan: {
      show: false,
    },
    formesign: {
      show: false,
    },
    formtolak: {
      show: false,
    },

    pejabats: [],
  }),
  computed: {
    ...mapState([
      "theme",
      "http",
      "device",
      "record",
      "records",
      "loading",
      "event",
      "snackbar",
    ]),
  },
  created() {
    this.setPage({
      crud: true,
      dataUrl: "api/user/approval",
    });
    this.fetchRecords();
    this.fetchPejabat();
  },
  mounted() {},
  methods: {
    ...mapActions([
      "setPage",
      "fetchRecords",
      "postAddNew",
      "postEdit",
      "postUpdate",
      "postConfirmDelete",
      "assignFileBrowse",
      "setRecord",
      "setLoading",
    ]),

    openTeruskan: function (val) {
      this.record.id = val;
      this.formteruskan.show = true;
    },

    openEsign: function (val) {
      this.record.id = val;
      this.formesign.show = true;
    },

    openTolak: function (val) {
      this.record.id = val;
      this.formtolak.show = true;
    },

    fetchPejabat: async function () {
      let { data: data } = await this.http.post(
        "api/master-data/combo-pejabat"
      );
      this.pejabats = data;
    },

    postEsign: async function () {
      try {
        this.setLoading({ dialog: true, text: "Proses tandatangan..." });
        let {
          data: { status, message },
        } = await this.http.post("api/user/approval-esign", this.record);

        if (!status) {
          this.snackbar.color = "red";
          this.snackbar.text = message;
          this.snackbar.state = true;
          return;
        }

        this.snackbar.color = this.theme.color;
        this.snackbar.text = message;
        this.snackbar.state = true;
        this.fetchRecords();
      } catch (error) {
        this.snackbar.color = "red";
        this.snackbar.text = error;
        this.snackbar.state = true;
      } finally {
        this.setLoading({ dialog: false, text: "" });
        this.formesign.show = false;
      }
    },

    postTolak: async function () {
      try {
        this.setLoading({ dialog: true, text: "Proses tolak..." });
        let {
          data: { status, message },
        } = await this.http.post("api/user/approval-tolak", this.record);

        if (!status) {
          this.snackbar.color = "red";
          this.snackbar.text = message;
          this.snackbar.state = true;
          return;
        }

        this.snackbar.color = this.theme.color;
        this.snackbar.text = message;
        this.snackbar.state = true;
      } catch (error) {
        this.snackbar.color = "red";
        this.snackbar.text = error;
        this.snackbar.state = true;
      } finally {
        this.setLoading({ dialog: false, text: "" });
      }
    },

    postDownload(val) {
      window.open(val, "__blank");
    },
  },
};
</script>
