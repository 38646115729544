var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.device.desktop ? "home pa-6 grey lighten-4":"home pa-0 grey lighten-4"},[_c('v-row',[_c('v-col',{attrs:{"col":"12"}},[_c('v-card',{staticClass:"animated fadeInUp"},[_c('v-card-title',{staticClass:"flex flex-row-reverse purple lighten-1"},[_c('v-tooltip',{attrs:{"color":"green","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","small":"","icon":""}},on),[_c('v-icon',{attrs:{"color":"white"},on:{"click":_vm.postRefresh}},[_vm._v("refresh")])],1)]}}])},[_c('span',[_vm._v("Refresh Data")])]),_c('v-tooltip',{attrs:{"color":_vm.theme.color,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","small":"","icon":""}},on),[_c('v-icon',{attrs:{"color":"white"},on:{"click":_vm.printReport}},[_vm._v("mdi-printer")])],1)]}}])},[_c('span',[_vm._v("Cetak Laporan")])]),_c('v-spacer'),_c('v-col',{attrs:{"cols":_vm.device.desktop? 4 : 10}},[_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.record.bulan,"persistent":"","width":"290px","dense":"","hide-details":"","color":_vm.theme.color},on:{"update:returnValue":function($event){return _vm.$set(_vm.record, "bulan", $event)},"update:return-value":function($event){return _vm.$set(_vm.record, "bulan", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Bulan Surat","prepend-icon":"mdi-calendar","readonly":"","solo":"","color":"white","dense":"","hide-details":""},model:{value:(_vm.bulan),callback:function ($$v) {_vm.bulan=$$v},expression:"bulan"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-date-picker',{attrs:{"color":_vm.theme.color,"type":"month","scrollable":""},model:{value:(_vm.bulan),callback:function ($$v) {_vm.bulan=$$v},expression:"bulan"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"grey"},on:{"click":function($event){_vm.modal = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":_vm.theme.color},on:{"click":function($event){return _vm.$refs.dialog.save(_vm.bulan)}}},[_vm._v(" OK ")])],1)],1)],1)],1),_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(_vm.device.desktop),expression:"device.desktop"}],staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.records,"items-per-page":10,"color":_vm.theme.color,"loading":_vm.loading.table,"loading-text":"Loading... Please wait","search":_vm.search},scopedSlots:_vm._u([{key:"item.pengirim",fn:function(ref){
var value = ref.value;
return [_c('span',{class:value.bold},[_vm._v(_vm._s(value.text))])]}}])},[_c('v-progress-linear',{attrs:{"slot":"progress","color":_vm.theme.color,"height":"1","indeterminate":""},slot:"progress"})],1)],1),_c('v-list',{directives:[{name:"show",rawName:"v-show",value:(_vm.device.mobile),expression:"device.mobile"}],attrs:{"subheader":"","three-line":""}},[_c('v-list-item-group',{attrs:{"multiple":"","active-class":""}},_vm._l((_vm.filterItems),function(item){return _c('v-list-item',{key:item.id,staticStyle:{"border":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"color":_vm.theme.color,"input-value":active}})],1),_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(_vm._s(item.sended_date))]),_c('v-list-item-title',[_vm._v(_vm._s(item.nunker))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.perihal))])],1)]}}],null,true)})}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }