<template>
  <div :class="device.desktop ? `home pa-6 grey lighten-4`:`home pa-0 grey lighten-4`">
    <v-row>
      <v-col col="12">
        <v-card class="animated fadeInUp">
          <v-card-title class="flex flex-row-reverse purple lighten-1">
            <v-tooltip
              color="green"
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  small
                  icon
                  v-on="on"
                >
                  <v-icon
                    color="white"
                    @click="postRefresh"
                  >refresh</v-icon>
                </v-btn>
              </template>
              <span>Refresh Data</span>
            </v-tooltip>
            <v-tooltip
              :color="theme.color"
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  small
                  icon
                  v-on="on"
                >
                  <v-icon
                    color="white"
                    @click="printReport"
                  >mdi-printer</v-icon>
                </v-btn>
              </template>
              <span>Cetak Laporan</span>
            </v-tooltip>
            <v-spacer></v-spacer>
            <v-col :cols="device.desktop ? 4:9">
              <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="record.bulan"
                persistent
                width="290px"
                dense
                hide-details
                :color="theme.color"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="bulan"
                    label="Bulan Surat"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    solo
                    color="white"
                    dense
                    hide-details
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="bulan"
                  :color="theme.color"
                  type="month"
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="grey"
                    @click="modal = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    :color="theme.color"
                    @click="$refs.dialog.save(bulan)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-card-title>
          <v-data-table
            v-show="device.desktop"
            :headers="headers"
            :items="records"
            :items-per-page="10"
            class="elevation-2"
            :color="theme.color"
            :loading="loading.table"
            loading-text="Loading... Please wait"
            :search="search"
          >
            <v-progress-linear
              slot="progress"
              :color="theme.color"
              height="1"
              indeterminate
            ></v-progress-linear>
            <template v-slot:item.pengirim="{ value }">
              <span :class="value.bold">{{ value.text }}</span>
            </template>
          </v-data-table>
          <v-list
            subheader
            three-line
            v-show="device.mobile"
          >

            <v-list-item-group
              multiple
              active-class=""
            >
              <v-list-item
                v-for="item in filterItems"
                :key="item.id"
                style="border: ;1px solid"
              >
                <template v-slot:default="{ active }">
                  <v-list-item-action>
                    <v-checkbox
                      :color="theme.color"
                      :input-value="active"
                    ></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-subtitle>{{ item.tanggal_surat}}</v-list-item-subtitle>
                    <v-list-item-title>{{ item.nomor_surat }}</v-list-item-title>
                    <v-list-item-subtitle>{{ item.perihal}}</v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import jsPDF from "jspdf";

export default {
  name: "Home",
  data: () => ({
    num: 1,
    headers: [
      { text: "#", value: "num", align: "right", width: 80, sortable: false },
      { text: "Nomor", value: "nomor_surat" },
      { text: "Perihal", value: "perihal" },
      { text: "Tanggal", value: "tanggal_surat", align: "center" },
    ],

    bulan: null,

    search: null,
    lampiran: null,

    dialog: false,
    modal: false,

    bulans: [
      { text: "Januari", value: "01" },
      { text: "Pebruari", value: "02" },
      { text: "Maret", value: "03" },
      { text: "April", value: "04" },
      { text: "Mei", value: "05" },
      { text: "Juni", value: "06" },
      { text: "Juli", value: "07" },
      { text: "Agustus", value: "03" },
    ],

    add: false,
    edit: false,

    formdraft: {
      show: false,
    },

    formteruskan: {
      show: false,
    },

    pejabats: [],
  }),
  computed: {
    ...mapState([
      "page",
      "theme",
      "http",
      "device",
      "record",
      "records",
      "loading",
      "event",
      "snackbar",
    ]),
    filterItems() {
      if (this.search != null) {
        return this.records.filter((item) => {
          return (
            item.perihal.toLowerCase().indexOf(this.search.toLowerCase()) > -1
          );
        });
      } else {
        return this.records;
      }
    },
  },
  created() {
    this.setPage({
      crud: true,
      dataUrl: "api/operator/report-surat-keluar/" + this.bulan,
      title: "LAPORAN SURAT KELUAR",
      subtitle: "Berikut adalah seluruh laporan surat keluar yang tersedia",
      breadcrumbs: [
        {
          text: "Laporan Surat Keluar",
          disabled: false,
          href: "",
        },
      ],
      add: false,
      edit: false,
      actions: {
        refresh: true,
        add: true,
        edit: true,
        delete: true,
        bulkdelete: false,
        print: false,
        export: false,
        help: false,
      },
    });
    this.fetchRecords();
    this.fetchPejabat();
  },
  mounted() {},
  methods: {
    ...mapActions([
      "setPage",
      "fetchRecords",
      "postAddNew",
      "postEdit",
      "postUpdate",
      "postConfirmDelete",
      "assignFileBrowse",
      "setRecord",
      "setLoading",
    ]),
    postRefresh: function () {
      this.setPage({
        crud: true,
        dataUrl: "api/operator/report-surat-keluar/" + this.bulan,
      });
      this.fetchRecords();
    },
    fetchPejabat: async function () {
      let { data: data } = await this.http.post(
        "api/master-data/combo-pejabat"
      );
      this.pejabats = data;
    },
    openSurat: function (val) {
      this.$router.push({
        name: "operator-surat-masuk-read",
        params: { id: val },
      });
    },
    openKirimSurat: function (val) {
      this.$router.push({
        name: "operator-surat-masuk-disposisi-index",
        params: { id: val },
      });
    },
    postAddNew: function () {
      this.$router.push({ name: "operator-surat-masuk-create" });
    },
    postEdit: function (val) {
      this.$router.push({
        name: "operator-surat-masuk-edit",
        params: { id: val },
      });
    },
    postDraftSurat: function (val) {
      this.$router.push({
        name: "operator-surat-keluar-draft",
        params: { id: val },
      });
    },

    postUpdateRecord: function () {
      this.postUpdate(this.record).then(() => {
        this.fetchRecords();
        this.path = "";
        this.add = false;
        this.edit = false;
      });
    },

    postDeleteRecord: function (val) {
      this.postConfirmDelete(val);
    },

    uploadFile: function () {
      this.assignFileBrowse({
        fileType: [".jpeg", ".jpg", ".png"],
        query: {
          doctype: "storage",
        },
        callback: (response) => {
          setTimeout(() => {
            this.lampiran = response.name;
            this.record.lampiran = response.name;
          }, 500);
        },
      });
    },

    postDownload(val) {
      window.open(val, "__blank");
    },

    printReport: async function () {
      let { data } = await this.http.get(
        "api/operator/report-surat-keluar-pdf/" + this.bulan
      );

      this.data = data;

      var doc = new jsPDF("l", "mm", [297, 210]);
      doc.fromHTML(data);
    },
  },
  watch: {
    bulan: function () {
      this.postRefresh();
    },
  },
};
</script>
